import React from 'react';
import {
  Datagrid,
  List,
  DateField,
  TextField,
  FunctionField,
  ReferenceField
} from 'react-admin';
import moment from 'moment'

export const ReferralList = (props) => {

  const _calculateServiceFee = (appointment) => {
    const { takePercentage, takeAmount } = appointment.service.provider
    const { subtotal } = appointment

    // TODO - recurring
    return subtotal * takePercentage + takeAmount
  }

  const ReferrerField = ({ referral }) => {
    const referrerText = referral.userReferrer?.email
    const code = referral.code
    return (
      <span>{referrerText || code}</span>
    );
  };

  const GrossRevenueField = ({ appointment }) => {
    if (!appointment._id){
      return <span></span>
    }
    return (
      <span>{_calculateServiceFee(appointment).toFixed(2)}</span>
    );
  };

  // const ReferredTotalGrossRevenueField = ({ referral }) => {
  //   let totalGrossRevenue = 0
  //   referral.appointmentsReferred.forEach(appointment => {
  //     const grossRevenue = _calculateServiceFee(appointment)
  //     totalGrossRevenue += grossRevenue
  //   })
  //   return (
  //     <span>{totalGrossRevenue.toFixed(2)}</span>
  //   );
  // };

  const PreviousReferrerAppointmentsField = ({ referral }) => {
    return (
      <span>{referral.previousAppointmentsReferrer.length}</span>
    );
  };

  const ReferredAppointmentsField = ({ referral }) => {
    return (
      <span>{referral.appointmentsReferred.length}</span>
    );
  };

  const AppointmentField = (props) => {
    const { record } = props;
    return record._id ? (
        <span>
            {moment(record.createdAt).format('M/D/YY')}: {record.service.name} at {record.service.provider.name}
        </span>
    ) : null;
  };

  return (
    <List {...props} sort={{ field: 'name', order: 'asc' }} bulkActionButtons={false} pagination={null}>
      <Datagrid>
        <DateField sortable={false} label="created" source="createdAt" />

        <FunctionField
          label="referrer"
          render={ referral => ( <ReferrerField referral={referral}/> ) }
        />
        <TextField sortable={false} label="referred" source="userReferred.email" />
        <FunctionField
          label="previous referrer appointments"
          render={ referral => ( <PreviousReferrerAppointmentsField referral={referral}/> ) }
        />
        <ReferenceField sortable={false} label="referrer appointment" source="appointmentReferrer._id" reference="appointments">
          <AppointmentField/>
        </ReferenceField>
        <TextField sortable={false} label="referrer appointment cost" source="appointmentReferrer.subtotal" />
        <FunctionField
          label="referrer appointment gross revenue"
          render={ referral => ( <GrossRevenueField appointment={referral.appointmentReferrer}/> ) }
        />
        <FunctionField
          label="referred appointments"
          render={ referral => ( <ReferredAppointmentsField referral={referral}/> ) }
        />
        <ReferenceField sortable={false} label="referred appointment" source="appointmentReferred._id" reference="appointments">
          <AppointmentField/>
        </ReferenceField>
        <TextField sortable={false} label="referred appointment cost" source="appointmentReferred.subtotal" />
        <FunctionField
          label="referred appointment gross revenue"
          render={ referral => ( <GrossRevenueField appointment={referral.appointmentReferred}/> ) }
        />
        {/* <FunctionField
          label="referred total gross revenue"
          render={ referral => ( <ReferredTotalGrossRevenueField referral={referral}/> ) }
        /> */}
      </Datagrid>
    </List>
  )
}
