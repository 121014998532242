import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  BooleanField
} from 'react-admin';
import { BOOKING_SYSTEM_TYPES_LOOKUP } from './enums'

const TypeField = ({ record, source }) => {
  const type = record[source]
  return (<span>{BOOKING_SYSTEM_TYPES_LOOKUP[type]}</span>);
};


export const BookingSystemList = (props) => (
  <List {...props} sort={{ field: 'name', order: 'asc' }} bulkActionButtons={false} pagination={null}>
    <Responsive
      small={
        <SimpleList linkType="show" primaryText={record => record.name} />
      }
      medium={
        <Datagrid>
          <TextField sortable={false} source="name" />
          <BooleanField sortable={false} source="isLive" label="Is Live on Skoop"/>
          <TypeField sortable={false} source="type" />
          <TextField sortable={false} source="leads" />
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
)

