import React, { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required
} from 'react-admin';
import { makeRequest } from '../../helpers'
import { SubcategoriesInput } from './inputs/SubcategoriesInput';
import { AutocompleteObjectArrayInput } from '../../components/AutocompleteObjectArrayInput'
import { LEAD_STATUSES, ADMIN_OWNERS } from './enums';

export const LeadCreate = (props) => {
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    getNeighborhoods();
    getCategories();
    getSubcategories();
  }, []);

  const getNeighborhoods = async () => {
    const neighborhoodsResponse = await makeRequest('/neighborhoods');
    setNeighborhoods(neighborhoodsResponse);
  };

  const getCategories = async () => {
    const categoriesResponse = await makeRequest('/categories');
    setCategories(categoriesResponse);
  }

  const getSubcategories = async () => {
    const subcategoriesResponse = await makeRequest('/subcategories');
    setSubcategories(subcategoriesResponse);
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()}/>
        <ReferenceInput label="Booking System" source="bookingSystem" reference="booking_systems">
          <SelectInput optionValue="_id"/>
        </ReferenceInput>
        <AutocompleteObjectArrayInput source="neighborhoods" choices={neighborhoods} optionValue="_id" />
        <AutocompleteObjectArrayInput source="categories" choices={categories} optionValue="_id" />
        <SubcategoriesInput subcategories={subcategories}/>
        <SelectInput initialValue="open" source="status" choices={LEAD_STATUSES}/>
        <SelectInput source="skoopAdminOwner" choices={ADMIN_OWNERS} />
      </SimpleForm>
    </Create>
  );
};
