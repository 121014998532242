import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const UrlField = ({ record, source }) => <a href={`https://www.skoop-admin.com/#/services/${record[source]}`}>Edit</a>;
UrlField.defaultProps = { label: '' };


export const ServiceIncompleteList = props => (
    <List pagination={null} bulkActionButtons={false} {...props} title="Incomplete Services">
        <Datagrid isRowSelectable={() => false}>
            <UrlField sortable={false} source="id"/>
            <TextField sortable={false} source="name" />
            <TextField sortable={false} label="price" source="displayPrice" />
            <TextField sortable={false} label="provider" source="provider.name"/>
            <TextField sortable={false} label="neighborhood" source="provider.neighborhood" />
            <TextField sortable={false} label="address" source="provider.address" />
        </Datagrid>
    </List>
);
