import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab } from 'react-admin';

import { CircularProgress } from '@mui/material';

import { makeRequest } from '../../../../helpers'

export const PublicAcuity = (props) => {
  const [providers, setProviders] = useState([])
  const [calendarsLoading, setCalendarsLoading] = useState(true)
  const [writeCalendars, setWriteCalendars] = useState([])

  useEffect(() => {
    getProviders();
    getGoogleCalendars();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };


  const getGoogleCalendars = async () => {
    const calendarsResponse = await makeRequest('/google_calendars');
    setWriteCalendars(calendarsResponse.filter( calendar => calendar.accessRole === 'owner'));
    setCalendarsLoading(false);
  };

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.public_acuity.integrationProviderId" label="Acuity Owner" helperText="https://app.acuityscheduling.com/schedule.php?owner=<OWNER>"/>
      {calendarsLoading ? <>
        <CircularProgress/>
        <span>Loading calendar list via Google Calendar API</span>
      </> : null}
      <SelectInput disabled={calendarsLoading} source="integrationDetails.google.writeCalendarId" label="Choose a write calendar" choices={writeCalendars}/>
    </FormTab>
  );
};
