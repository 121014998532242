import React from 'react';
import { AutocompleteInput } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
const MASSAGE_CATEGORY = `5fc96e1497bbe63f8e8b45ba`
const FACIAL_CATEGORY = `5fc96e1497bbe63f8e8b45b4`


export const prepend = (value, array) => {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export const SubcategoryInput = ({subcategories}) => {
  const { values } = useFormState();
  const { change } = useForm()

  if (!values.category && !!values.subcategory){
    console.log('change subcategory to null')
    change('subcategory', null)
}

  if (![MASSAGE_CATEGORY, FACIAL_CATEGORY].includes(values.category) || subcategories.length === 0){
    return null
  }

  let choices = subcategories.filter(subcategory => values.category === subcategory.category._id)
  const disabled = subcategories.length === 0 || (values.categorizedByGpt && !values.gptCategorizationMarkedIncorrect && !!values.subcategory )

  return (
    <AutocompleteInput
      disabled={disabled}
      source="subcategory"
      optionValue="_id"
      choices={prepend({_id: null, name: ''}, choices)}
      emptyValue={null}
      emptyText=''
    />
  )
}
