import React from 'react';
import { AutocompleteArrayInput } from 'react-admin';

export const AutocompleteObjectArrayInput = ({source, choices, optionText='name'}) => {
  return (
    <AutocompleteArrayInput 
      source={source} 
      choices={choices} 
      parse={value => value && value.map(v => choices.find(choice => choice._id === v))}
      format={value => value && value.map(v => v._id)}
      optionValue="_id"
      optionText={optionText}
    />
  );
};
