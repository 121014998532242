import { API_URL } from './environment'

export default {
    // authentication
    login: ({ username, password }) =>  {
        const request = new Request(`${API_URL}/admin/login`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                console.log('auth', auth)
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((error) => {
                if (error.message === 'Unauthorized'){
                    throw new Error('Invalid credentials')
                } else {
                    throw new Error('Network error')
                }
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject({ message: 'Unauthorized' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
};