import React from 'react';
import {
  Edit,
  TextInput,
  Toolbar,
  SaveButton,
  SimpleForm,
} from 'react-admin';

import { validateTime, validatePMTime, validateOpenAndClose } from '../providers/util/validations'

const BottomToolbar = props => (
  <Toolbar {...props} >
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const ProviderScheduleEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<BottomToolbar/>}>
        <TextInput validate={validateTime} label='Sunday Open' source="openHours.Sunday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]} label='Sunday Close' source="openHours.Sunday.end"/>
        <TextInput validate={validateTime} label='Monday Open' source="openHours.Monday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]}label='Monday Close' source="openHours.Monday.end"/>
        <TextInput validate={validateTime} label='Tuesday Open' source="openHours.Tuesday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]} label='Tuesday Close' source="openHours.Tuesday.end"/>
        <TextInput validate={validateTime} label='Wednesday Open' source="openHours.Wednesday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]} label='Wednesday Close' source="openHours.Wednesday.end"/>
        <TextInput validate={validateTime} label='Thursday Open' source="openHours.Thursday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]}label='Thursday Close' source="openHours.Thursday.end"/>
        <TextInput validate={validateTime} label='Friday Open' source="openHours.Friday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]}label='Friday Close' source="openHours.Friday.end"/>
        <TextInput validate={validateTime} label='Saturday Open' source="openHours.Saturday.start"/>
        <TextInput validate={[validateTime, validatePMTime(), validateOpenAndClose()]} label='Saturday Close' source="openHours.Saturday.end"/>
      </SimpleForm>
    </Edit>
  )
};
