import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Edit,
  SimpleForm,
  Toolbar,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SaveButton,
} from 'react-admin';
import { AdminCancelButton } from './AdminCancelButton';

import { parseBookingSystem } from '../providers/util/parseBookingSystem'

const HasReviewField = ({ record, source }) => {
  return (
    <span>{!!record[source] ? 'Yes' : undefined}</span>
  );
}

const BookingSystemField = ({ provider }) => {
  return (
    <span>{parseBookingSystem(provider)}</span>
  );
};

export const AppointmentList = (props) => {
  return(
    <List pagination={null} bulkActionButtons={false} {...props}>
      <Datagrid isRowSelectable={() => false} rowClick="edit">
        <TextField sortable={false} source="id" />
        <TextField sortable={false} source="status" />
        <DateField sortable={false} label="Time" source="dateTime" showTime />
        <TextField sortable={false} label="Name" source="user.fullName" />
        <TextField sortable={false} label="Email" source="user.email" />
        <TextField
          sortable={false}
          label="Provider"
          source="service.provider.name"
        />
        <TextField
          sortable={false}
          label="Neighborhood"
          source="service.provider.neighborhood"
        />
        <TextField sortable={false} label="Service" source="service.name" />
        <TextField sortable={false} label="Price" source="subtotal" />
        <FunctionField
          label="Booking System"
          render={ appointment => ( <BookingSystemField provider={appointment.provider}/> ) }
        />
        <HasReviewField sortable={false} source="review" />
      </Datagrid>
    </List>
  )
};


export const AppointmentEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...props} >
            <SaveButton />
            <AdminCancelButton/>
          </Toolbar>
        }
      >
        <TextInput disabled source="id" />
        <TextInput disabled source="status" />
        <TextInput disabled label="First name" source="user.firstName" />
        <TextInput disabled label="Last name" source="user.lastName" />
        <TextInput disabled label="Email" source="user.email" />
        <TextInput
          disabled
          label="Provider"
          source="provider.name"
        />
        <TextInput
          disabled
          source="service.provider.neighborhood"
          label="Neighborhood"
        />
        <TextInput disabled label="Service" source="service.name" />
        <DateTimeInput
          disabled
          label="Appointment time"
          source="dateTime"
        />
        <TextInput disabled label="Price" source="subtotal" />
        <TextInput disabled source="stripePaymentIntentId" />
        <BooleanInput source="isCharged" label="No Charge?" />
      </SimpleForm>
    </Edit>
  );
};
