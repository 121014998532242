import React, { useState } from 'react';
import { TextInput, Button } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import ReactJson from 'react-json-view'

import { makeRequest } from '../../../../../helpers'
import { REACT_JSON_DEFAULT_PROPS } from './index'

const FindGoogleBusinessButton = ({setGooglePlace}) => {
  const { values } = useFormState();
  const { change } = useForm();

  const findGoogleBusiness = async () => {
    try {
      const googlePlace = await makeRequest('/providers/google_place/search', { searchTerm: values.googleNameToSearch })
      change('googlePlaceId', googlePlace.place_id)
      setGooglePlace(googlePlace)
    } catch (err){
      alert('Internal Server Error trying to find Google Place Id')
    }
  }

  return (
    <Button disabled={!values.googleNameToSearch} label="Find Google Place ID" onClick={findGoogleBusiness}/>
  );
};

const SearchSection = ({savedGooglePlaceId, setGooglePlace, ...rest}) => {
  const [showSearchSection, setShowSearchSection] = useState(!savedGooglePlaceId)

  if (!showSearchSection){
    return <Button label="Update Google Place ID" onClick={() => setShowSearchSection(true)} style={{display: 'flex'}}/>
  }
  return (
    <div {...rest}>
      <TextInput source="googleNameToSearch" helperText="The provider's business name as listed on Google"/>
      <FindGoogleBusinessButton setGooglePlace={setGooglePlace}/>
    </div>
  )
}

export default (props) => {
  const savedGooglePlaceId = props.record.googlePlaceId
  const [googlePlace, setGooglePlace] = useState(undefined)

  const getGooglePlaceDetails = async () => {
    const googlePlaceResponse = await makeRequest(`/providers/${props.record.id}/google_place`)
    setGooglePlace(googlePlaceResponse)
  }

  const GooglePlaceDetailsButton = () => {
    const { values } = useFormState();
    const showButton = !!savedGooglePlaceId && !googlePlace && !values.googleNameToSearch
    return showButton ? <Button label="Get Google Place Details" onClick={getGooglePlaceDetails} style={{display: 'flex'}}/> : null
  }

  return (
    <div {...props}>
      <span style={{'font-weight': 'bold'}}>Google</span>
      <SearchSection {...props} savedGooglePlaceId={savedGooglePlaceId} setGooglePlace={setGooglePlace}/>
      <TextInput disabled label="Google Place ID" source="googlePlaceId" helperText={googlePlace ? googlePlace.name : undefined} {...props}/>
      <GooglePlaceDetailsButton />
      {googlePlace ? <ReactJson src={googlePlace} name={`Google Place Details`} {...REACT_JSON_DEFAULT_PROPS}/> : null}
    </div>
  );
};
