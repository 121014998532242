import { API_URL } from './environment'

export const makeRequest = async (endpoint, params = {}) => {
    try {
      let urlObject = new URL(`${API_URL}${endpoint}`)
      Object.keys(params).forEach(key => urlObject.searchParams.append(key, params[key]))
      const auth = JSON.parse(localStorage.getItem('auth'));
      const token = auth ? auth.token : null
      const options = { headers: { 'authorization': token } }
      console.log("Requesting: ", urlObject, options)
      const response = await fetch(urlObject, options);
  
      if (!response.ok){
        console.log('not ok', response.statusText)
        const errJson = await response.json();
        throw errJson
      }
  
      const json = await response.json();
      console.log("Request succeeded: ", urlObject, options)
      return json
    } catch (err) {
      console.log('err', err)
      throw err
    }
}

export const fetchPut = async (endpoint, opts = {}) => {
  console.log("${API_URL}${endpoint}", `${API_URL}${endpoint}`)
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth ? auth.token : null
    const response = await fetch(`${API_URL}${endpoint}`, {
      method: 'put',
      body: JSON.stringify(opts),
      headers: {
        'Content-Type': 'application/json',
        'authorization': token,
      }
    });
    
    if (!response.ok){
      console.log('not ok', response.statusText)
      const errJson = await response.json();
      throw errJson
    }

    const json = await response.json();
    console.log("Put succeeded: ", endpoint)
    return json
  } catch (err) {
    console.log('err', err)
    throw err
  }
}

// exact same as fetch put
export const fetchPost = async (endpoint, opts = {}) => {
  console.log("${API_URL}${endpoint}", `${API_URL}${endpoint}`)
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth ? auth.token : null
    const response = await fetch(`${API_URL}${endpoint}`, {
      method: 'post',
      body: JSON.stringify(opts),
      headers: {
        'Content-Type': 'application/json',
        'authorization': token,
      }
    });
    
    if (!response.ok){
      console.log('not ok', response.statusText)
      const errJson = await response.json();
      throw errJson
    }

    const json = await response.json();
    console.log("Post succeeded: ", endpoint)
    return json
  } catch (err) {
    console.log('err', err)
    throw err
  }
}
