import React, {useState, useEffect} from 'react';
import { List, Datagrid, TextField, Filter, SelectInput, AutocompleteInput, required, maxValue, minValue } from 'react-admin';
import { Create, Edit, SimpleForm, TextInput, SaveButton, Toolbar, NumberInput, EditButton, BooleanInput, BooleanField, NumberField, Labeled } from 'react-admin';
import { makeRequest } from './helpers'
import { useFormState } from 'react-final-form';
import isEmpty from 'lodash.isempty'
import { SubcategoryInput, prepend } from './components/SubcategoryInput';


export const ServiceEdit = (props) => {
    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])

    useEffect(() => {
        getCategories()
        getSubcategories()
    }, [])

    const getCategories = async () => {
        const categoriesResponse = await makeRequest('/categories', {isSearchable: true})
        setCategories(categoriesResponse)
    }

    const getSubcategories = async () => {
        const subcategoriesResponse = await makeRequest('/subcategories', {isCurrent: true})
        setSubcategories(subcategoriesResponse)
    }

    const NameIntegrated = () => {
        return <TextInput disabled style={{width: 350}} label="Integrated Name" source="nameIntegrated"/>
    }

    const checkIfManuallyCreatedService = (service) => {
        return isEmpty(service.integrationDetails)
    }

    const checkIfIntegrated = (service) => {
        const isManuallyCreatedService = checkIfManuallyCreatedService(service)
        return !isManuallyCreatedService
    }

    const CategoryInput = () => {
        const { values } = useFormState();

        const disabled = categories.length === 0 || (values.categorizedByGpt && !values.gptCategorizationMarkedIncorrect)
        return <AutocompleteInput disabled={disabled} source="category" choices={prepend({_id: null, name: ''}, categories)} optionValue="_id" emptyValue={null} emptyText=''/>
    }

    const NameSection = ({ record }) => {
        const isIntegrated = checkIfIntegrated(record)
        const nameManualLabel = isIntegrated ? 'Name (Override)' : 'Name'
        return (
            <>
                {isIntegrated && <div style={{marginRight: 10, display: 'inline-block'}}>
                    <NameIntegrated />
                </div>}
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <TextInput style={{width: 350}} source="nameManual" label={nameManualLabel} validate={isIntegrated ? undefined : required()}/>
                </div>
            </>
        )
    }

    const PriceIntegrated = () => {
        return (
            <NumberInput
                disabled
                label="Integrated Price"
                source="priceIntegrated"
            />
        )
    }

    const PriceNumberSection = ({ record }) => {
        const isIntegrated = checkIfIntegrated(record)
        const priceManualLabel = isIntegrated ? 'Price (Override)' : 'Price'
        return (
            <>
                {isIntegrated && <div style={{marginRight: 10, display: 'inline-block'}}>
                    <PriceIntegrated/>
                </div>}
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <NumberInput label={priceManualLabel} source="priceManual" />
                </div>
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <NumberInput label="Discounted price" source="priceDiscounted" />
                </div>
            </>
        )
    }

    const PriceDescriptionSection = ({ record }) => {
        const isIntegrated = checkIfIntegrated(record)
        return (
            <>
                {isIntegrated && <div style={{marginRight: 10, display: 'inline-block'}}>
                    <TextInput style={{width: 250}} multiline disabled label="Integrated Price Description" source="priceDescriptionIntegrated" />
                </div>}
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <TextInput style={{width: 250}} multiline label="Override Price Description" source="displayPriceOverride" />
                </div>
            </>
        )
    }

    const DurationIntegrated = () => {
        return (
            <NumberInput
                disabled
                label="Integrated Duration"
                source="durationIntegrated"
            />
        )
    }

    const DurationSection = ({ record }) => {
        const isIntegrated = checkIfIntegrated(record)
        const durationManualLabel = isIntegrated ? 'Duration (Override)' : 'Duration'
        return (
            <>
                {isIntegrated && <div style={{marginRight: 10, display: 'inline-block'}}>
                    <DurationIntegrated />
                </div>}
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <NumberInput label={durationManualLabel} source="durationManual" />
                </div>
                <TextInput disabled label="Display Duration" source="displayDuration" />
            </>
        )
    }

    const DescriptionIntegrated = () => {
        return <TextInput disabled style={{width: 350}} label="Integrated Description" multiline source="descriptionIntegrated"/>
    }

    const DescriptionSection = ({ record }) => {
        const isIntegrated = checkIfIntegrated(record)
        const descriptionManualLabel = isIntegrated ? 'Description (Override)' : 'Description'
        return (
            <>
                {isIntegrated && <div style={{marginRight: 10, display: 'inline-block'}}>
                    <DescriptionIntegrated />
                </div>}
                <div style={{marginRight: 10, display: 'inline-block'}}>
                    <TextInput style={{width: 350}} label={descriptionManualLabel} multiline source="descriptionManual" />
                </div>
            </>
        )
    }

    const InHomeInput = ({record}) => {
        if (record.provider.inHomeOnly){
            return null
        }
        return <BooleanInput label="Available in-home" source="inHome" />
    }

    const GptSection = () => {
        const { values } = useFormState();

        if (values.categorizedByGpt === undefined){
            return null
        }

        if (values.categorizedByGpt === false){
            return <Labeled label="Categorized by GPT"><BooleanField source="categorizedByGpt" /></Labeled>
        }

        return (
            <>
                <Labeled label="Categorized by GPT"><BooleanField source="categorizedByGpt" /></Labeled>
                {/* <BooleanInput label='Approve GPT Categorization' source="gptCategorizationApproved" /> */}
                <BooleanInput label='Mark GPT Categorization as Incorrect' source="gptCategorizationMarkedIncorrect" />
                {/* <Labeled label="GPT Category Confidence Score"><NumberField style={{width: 300}} source="gptCategoryConfidenceScore" /></Labeled>
                <Labeled label="GPT Subcategory Confidence Score"><NumberField style={{width: 300}} source="gptSubcategoryConfidenceScore" /></Labeled> */}
            </>
        )
    }

    return (
    <Edit {...props}>
        <SimpleForm toolbar={
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        }>
            <TextInput disabled source="id" />

            <NameSection/>
            <PriceNumberSection/>
            <PriceDescriptionSection/>
            <TextInput disabled label="Display Price" source="displayPrice" />
            <DurationSection/>
            <DescriptionSection/>

            <TextInput disabled label="provider" source="provider.name" />
            <TextInput disabled label="neighborhood" source="provider.neighborhood" />
            <TextInput disabled label="address" source="provider.address" />
            <InHomeInput/>

            <GptSection/>

            <CategoryInput/>
            <SubcategoryInput subcategories={subcategories}/>

            {/* <AutocompleteArrayInput source="categoryIds" choices={categories} optionValue="_id" /> */}
            {/* <SubcategoriesInput subcategories={subcategories}/> */}
            <BooleanInput label="Hide this service?" source="manuallyExcluded" />
        </SimpleForm>
    </Edit>
)};

export const ServiceCreate = (props) => {
    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])
    const [providers, setProviders] = useState([])

    useEffect(() => {
        getCategories()
        getSubcategories()
        getProviders()
    }, [])

    const getCategories = async () => {
        const categoriesResponse = await makeRequest('/categories', {isSearchable: true})
        setCategories(categoriesResponse)
    }

    const getSubcategories = async () => {
        const subcategoriesResponse = await makeRequest('/subcategories')
        setSubcategories(subcategoriesResponse)
    }

    const getProviders = async () => {
        const providersResponse = await makeRequest('/providers')
        const providersWithAddress = providersResponse
            .filter(provider =>
                !!provider.integrationDetails && !!provider.integrationDetails.google && (
                    Object.keys(provider.integrationDetails).length === 1
                    ||
                    (!!provider.integrationDetails.google &&  Object.keys(provider.integrationDetails).length === 2)
                )
            )
            .map(provider => ({...provider, name: provider.name + ' ' + provider.address}))
        setProviders(providersWithAddress)
    }

    const requiredRange = (min, max) => {
        return [required(), maxValue(max, `No more than ${max}`), minValue(min, `No less than ${min}`)]
    }

    const InHomeInput = () => {
        const { values } = useFormState();

        const provider = providers.find(p => p._id === values.provider)
        if (!provider || provider.inHomeOnly){
            return null
        }
        return <BooleanInput label="Available in-home" source="inHome" />
    }

    return (
    <Create {...props}>
        <SimpleForm toolbar={
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        }>
            <AutocompleteInput source="provider" choices={providers} optionValue="_id" validate={required()}/>
            <TextInput source="nameManual" validate={required()}/>
            <NumberInput label="Price" source="priceManual" validate={requiredRange(0, 100000)} />
            <TextInput label="Description" multiline source="descriptionManual" validate={required()}/>
            <NumberInput label="Service length (in minutes)" source="durationManual" validate={required()}/>
            <InHomeInput/>
            <AutocompleteInput source="category" choices={prepend({_id: null, name: ''}, categories)} optionValue="_id" emptyValue={null} emptyText='' />
            <SubcategoryInput subcategories={subcategories}/>

            {/* <AutocompleteArrayInput source="categoryIds" choices={categories} optionValue="_id" /> */}
            {/* <SubcategoriesInput subcategories={subcategories}/> */}
        </SimpleForm>
    </Create>
)};

const Empty = () => {
    return <div>Navigate to providers to view services</div>
}

export const SERVICE_STATUSES = [
    {id: 'visible', name: 'Visible In-App'},
    {id: 'manuallyHidden', name: 'Marked As Hidden'},
    {id: 'missingPrice', name: 'No Price (Hidden by Default)'},
    {id: 'missingTag', name: 'Untagged (Hidden by Default)'},
]

const buildLookup = (arrayOfObjects) => {
    let lookup = {}
    arrayOfObjects.forEach(object => lookup[object.id] = object.name)
    return lookup
}

const SERVICE_STATUSES_LOOKUP = buildLookup(SERVICE_STATUSES)


const ServiceFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="status" choices={SERVICE_STATUSES} alwaysOn/>
    </Filter>
);

const StatusField = ({ record, source }) => {
    const status = record[source]
    return (<span>{SERVICE_STATUSES_LOOKUP[status]}</span>);
};

export const ServiceList = props => (
    <List
        pagination={null}
        bulkActionButtons={false}
        empty={<Empty/>}
        filters={<ServiceFilter />}
        {...props}
    >
        <Datagrid isRowSelectable={() => false} rowClick="edit">
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="name" />
            <TextField sortable={false} label="price" source="displayPrice" />
            <TextField sortable={false} label="category" source="category.name" />
            <TextField sortable={false} label="subcategory" source="subcategory.name" />
            <TextField sortable={false} label="provider" source="provider.name"/>
            <TextField sortable={false} label="neighborhood" source="provider.neighborhood" />

            {/* <StatusField source="status"/> */}
            {/* <TextField sortable={false} label="address" source="provider.address" /> */}
            <EditButton/>
        </Datagrid>
    </List>
);
