import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab } from 'react-admin';

import { makeRequest } from '../../../../helpers'

export const Acuity = (props) => {
  const [providers, setProviders] = useState([])

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.acuity.apiKey" label="Booker API Key"/>
      <TextInput source="integrationDetails.acuity.userId" label="Booker User ID"/>
    </FormTab>
  );
};
