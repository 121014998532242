import React, { useEffect, useState } from 'react'
import { ImageField, ImageInput, required } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { CircularProgress } from '@mui/material';

import { fetchPost } from '../../../../helpers'
import { optionallyReplaceBlobWithS3Url } from './optionallyReplaceBlobWithS3Url'


export default (props) => {
  const { saveDisabled, setSaveDisabled, record } = props
  const providerId = record._id

  // HAX - after upgrading to latest react admin (react-final-form -> react-hook-form),
  // we should be able to use a hook to do this directly, without re-rendering
  const { change } = useForm();
  const { values } = useFormState()
  const [s3Url, setS3Url] = useState(undefined)

  useEffect(() => {
    optionallyReplaceBlobWithS3Url({values, s3Url, replaceBlobWithS3Url})
  }, [s3Url])

  const replaceBlobWithS3Url = ({fieldToOverwrite, newValue}) => {
    change(fieldToOverwrite, newValue)
    setSaveDisabled(false)
    setS3Url(undefined)
  }

  const getBase64AndSaveToS3 = ({uploadedFile, fileNamePrefix=''}) => {
    setSaveDisabled(true)
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = async () => {
      const base64String = reader.result.replace(/^data:image\/\w+;base64,/, "")
      const s3UrlResponse = await fetchPost(`/s3_photos`, {
        base64: base64String,
        fileName: fileNamePrefix + new Date().getTime().toString(),
        folder: `providers/${providerId}`
      })
      setS3Url(s3UrlResponse)
    };
    reader.onerror = (error) => {
      alert(`There was an error uploading that photo to Skoop's image repository.  Please reach out for help.`)
    };
 }

 const onChangeThumbnail = (uploadedFile) => {
    if (!!uploadedFile){
      getBase64AndSaveToS3({uploadedFile, fileNamePrefix: 'thumbnail'})
    }
  }

  const onChangeMediaUrls = (mediaUrls) => {
    const uploadedFile = mediaUrls.find( m => !!m.size ) // find a better way to identify the recently uploaded file
    if (!!uploadedFile){
      getBase64AndSaveToS3({uploadedFile})
    }
  }

  return (
    <>
      <ImageInput source="thumbnailWithSrc" label="Thumbnail" accept="image/*" validate={required()} onChange={onChangeThumbnail}>
        <ImageField source="src"/>
      </ImageInput>
      {/* TODO - is there a way to disable ImageInput?? disable prop is not working */}
      {saveDisabled ? <>
        <CircularProgress/>
        <span>Image uploading. Please wait to upload or remove additional images.</span>
      </> : null}
      <ImageInput multiple source="mediaUrlsWithSrc" label="Other photos" accept="image/*" onChange={onChangeMediaUrls}>
        <ImageField source="src"/>
      </ImageInput>
    </>
  )
}
