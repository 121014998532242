import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  BooleanField,
  NumberField
} from 'react-admin';

export default (props) => (
  <List {...props} sort={{ field: 'sortOrder', order: 'asc' }} bulkActionButtons={false} pagination={null}>
    <Responsive
      small={
        <SimpleList linkType="show" primaryText={record => record.title} />
      }
      medium={
        <Datagrid>
          <TextField sortable={false} source="title"/>
          <BooleanField sortable={false} source="active" label="Active"/>
          <NumberField sortable={false} source="sortOrder" label="Sort Order"/>
          <NumberField sortable={false} source="serviceIds.length" label="# of Services"/>
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
)
