import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab, Button } from 'react-admin';
import { useForm, useFormState } from 'react-final-form'

import { makeRequest } from '../../../../helpers'

export const Booker = (props) => {
  const [providers, setProviders] = useState([])

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getLocationId = async ({form, values}) => {
    const accountName = values?.integrationDetails?.booker?.accountName

    if (!accountName){
      alert('Fill out account name to get location id')
      return
    }

    const locationIdResponse = await makeRequest('/booker/location', {accountName});
    form.change('integrationDetails.booker.integrationLocationId', locationIdResponse)
  };

  const LocationIdButton = () => {
    const form = useForm()
    const { values } = useFormState()

    return <Button label="Get Location Id" onClick={() => getLocationId({form, values})} />
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.booker.accountName" label="Booker account name" helperText="https://go.booker.com/location/<ACCOUNT_NAME>/service-menu"/>
      <LocationIdButton/>
      <TextInput source="integrationDetails.booker.integrationLocationId" label="Booker location id"/>
    </FormTab>
  );
};
