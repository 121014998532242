import React, { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  Toolbar,
  required,
  Button
} from 'react-admin';
import { requiredRange } from '../util/validations'
import { parseBookingSystem } from '../util/parseBookingSystem'
import { makeRequest, fetchPost } from '../../../helpers'
import { useFormState } from 'react-final-form';
import get from 'lodash.get'

const getQueryParams = ({provider, bookingSystem}) => {
  const queryParamsForBookingSystem = {
    boulevard: { businessId: provider.integrationDetails.boulevard?.businessId },
    mindbody: { siteId: provider.integrationDetails.mindbody?.siteId },
    square: { credentialSetId: provider.squareCredentialSet },
    zenoti: { apiKey: provider.integrationDetails.zenoti?.apiKey },
  }
  return queryParamsForBookingSystem[bookingSystem]
}

const optionTextAndValue = (bookingSystem) => {
  if (bookingSystem === 'mindbody'){
    return { optionText: 'Name', optionValue: 'Id'}
  }
}

export const AddLocation = (props) => {
  const { record, exitAddLocationMode } = props
  const provider = record
  const bookingSystem = parseBookingSystem(provider)
  const [locations, setLocations] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getLocations = async (provider) => {
    const endpoint = `/${bookingSystem}/locations`
    const queryParams = getQueryParams({provider, bookingSystem})

    try {
      const locationsResponse = await makeRequest(endpoint, queryParams);
      setLocations(locationsResponse)
    } catch (err){
      alert(err)
      return
    }
  };

  const LocationButton = () => <Button label="Get Locations" onClick={() => getLocations(provider)} />

  const checkReadyToConvert = (values) => {
    const requiredFields = ['neighborhood', 'address', 'latitude', 'longitude', 'locationId']

    for (const field of requiredFields){
      if ( !get(values, field) ){
        return { isReadyToConvert: false, missingField: field}
      }
    }

    return { isReadyToConvert: true }
  }

    
  const addLocation = async (values) => {
    const { isReadyToConvert, missingField } = checkReadyToConvert(values)
    if (!isReadyToConvert){
      alert(`Make sure to fill out all the fields. Missing: ${missingField}`)
      return
    }

    try {
      setIsLoading(true)
      await fetchPost('/add_location', {...values, providerId: provider._id})
      exitAddLocationMode()
      alert('success')
      setIsLoading(false)
    } catch(err){
      alert(err)
      setIsLoading(false)
      return
    }
  }

  const CustomSaveButton = () => {
    const { values } = useFormState();

    return (
      <Button
        style={{
          backgroundColor: 'blue',
          color: 'white',
          height: 36,
          marginLeft: 20,
          padding: 15,
        }}
        disabled={isLoading}
        label="Add Location"
        onClick={() => addLocation(values)}
      />
    )
  }

  return (
        <SimpleForm
          toolbar={
            <Toolbar {...props}>
              <CustomSaveButton/>
            </Toolbar>
          }
        >
          <TextInput source="neighborhood" validate={required()} />
          <TextInput source="address" validate={required()} className="w_50" />
          <NumberInput source="latitude" validate={requiredRange(0, 180)} />
          <NumberInput source="longitude" validate={requiredRange(-180, 0)} />
          <LocationButton />
          {
            locations.length ? 
            <SelectInput validate={required()} source="locationId" choices={locations} {...optionTextAndValue(bookingSystem)}/>
            : null
          }
        </SimpleForm>
  );
};
