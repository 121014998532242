import React from 'react';
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  BooleanField,
  Labeled,
  useRecordContext,
  TopToolbar,
  EditButton
} from 'react-admin';
import { LEAD_NOTE_TYPES_LOOKUP, OUTREACH_TYPES_LOOKUP } from '../leads/enums';

const GenericNoteSection = ({record}) => (
  <Labeled label="Note"><span>{record.body}</span></Labeled>
)

const ScheduledPhoneCallSection = ({record}) => (
  <>
    <Labeled label="Date of Phone Call"><span>{record.phoneCallDate}</span></Labeled>
    <Labeled label="Phone call note"><span>{record.phoneCallNote}</span></Labeled>
  </>
)

const OutreachSection = ({record}) => (
  <>
    <Labeled label="Date of Outreach"><span>{record.outreachDate}</span></Labeled>
    <Labeled label="Outreach type"><span>{OUTREACH_TYPES_LOOKUP[record.outreachType]}</span></Labeled>
    <Labeled label="Talked to a stakeholder on the phone?"><span>{record.outreachPhoneCallConnection ? 'yes' : 'no'}</span></Labeled>
    <Labeled label="Outreach note"><span>{record.outreachNote}</span></Labeled>
  </>
)

const NoteTypeSections = () => {
  const record = useRecordContext()
  const noteType = record.noteType || 'genericNote'
  switch(noteType) {
    case 'genericNote':
      return <GenericNoteSection record={record}/>
    case 'followUp':
      return <GenericNoteSection record={record}/>
    case 'scheduledPhoneCall':
      return <ScheduledPhoneCallSection record={record}/>
    case 'salesTemplateOutreach':
      return <OutreachSection record={record}/>
    default:
      return null
  }
}

const ShowActions = ({ basePath, data }) => {
  const record = useRecordContext()
  return <TopToolbar>
    {record.noteType === 'scheduledPhoneCall' && <EditButton basePath={basePath} record={data} />}
  </TopToolbar>
}

const LeadNoteShow = props => (
  <Show {...props} title="Lead Note" actions={<ShowActions/>}>
    <SimpleShowLayout>
      <DateField source="createdAt" showTime />
      
      <NoteTypeSections />

      <TextField label="Author" source="author.fullName" />
    </SimpleShowLayout>
  </Show>
);

export default LeadNoteShow;
