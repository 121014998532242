import React from 'react';
import { Link } from 'react-router-dom';

export default ({record}) => (
  <Link
    to={{
      pathname: '/services',
      search: `filter=${JSON.stringify({ provider: record.id })}`,
    }}
  >
    {record.name} services
  </Link>
);
