import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  BooleanField,
  EditButton,
  TopToolbar
} from 'react-admin';
import { BOOKING_SYSTEM_TYPES_LOOKUP, TWO_WAY_OPEN_API } from './enums'
import { TextFieldPreserveNewlines } from '../../components/TextFieldPreserveNewlines'

const TypeField = ({ record, source }) => {
  const type = record[source]
  return (<span>{BOOKING_SYSTEM_TYPES_LOOKUP[type]}</span>);
};
TypeField.defaultProps = { label: 'Type', addLabel: true };

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
      <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ApiDocumentationUrl = ({ record, source }) => {
  if (record.type === TWO_WAY_OPEN_API){
    return (
      <Labeled label="API Documentation URL">
        <span>{record[source]}</span>
      </Labeled>
    );
  }
  return null
};

const Title = ({record}) => <span>{record.name}</span>

export const BookingSystemShow = (props) => {
return(
  <Show {...props} actions={<Actions />} title={<Title/>}>
    <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="leads" />
        <BooleanField source="isLive" label="Is Live On Skoop"/>
        <TypeField source="type" />
        <ApiDocumentationUrl source="apiDocumentationUrl"/>
        <TextFieldPreserveNewlines multiline source="providerOnboardingInstructions" />
    </SimpleShowLayout>
  </Show>
)};
