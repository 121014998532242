import React, { useState, useEffect } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  SelectField,
  NumberField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  EditButton,
  TopToolbar,
  ListButton
} from 'react-admin';
import { ChevronLeft } from '@material-ui/icons'
import { makeRequest } from '../../helpers'

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
      <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const Title = ({record}) => <span>{record.title}</span>

export default (props) => {
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  useEffect(() => {
    getCategories()
    getSubcategories()
  }, []);

  const getCategories = async () => {
    const categoriesResponse = await makeRequest('/categories', {isSearchable: true})
    setCategories(categoriesResponse)
  }

  const getSubcategories = async () => {
    const subcategoriesResponse = await makeRequest('/subcategories', {isCurrent: true})
    setSubcategories(subcategoriesResponse)
  }

  return (
    <Show {...props} actions={<Actions />} title={<Title/>}>
      <SimpleShowLayout>
          <TextField source="title" />
          <BooleanField source="active" label="Active"/>
          <SelectField source="category" optionValue='_id' choices={categories} />
          <SelectField source="subcategory" optionValue='_id' choices={subcategories} />
          <BooleanField source="inHomeFilter" label="In-Home Only"/>
          <BooleanField source="hasDiscountFilter" label="Skoop Exclusive"/>
          <NumberField source="sortOrder" label="Sort Order"/>
          <ArrayField source="serviceIds" label="Services">
            <SingleFieldList linkType={false}>
              <ChipField source="providerAndServiceName" />
            </SingleFieldList>
          </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
};
