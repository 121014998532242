import React from 'react'
import { useRecordContext } from 'react-admin';

import { Google } from './google'
import { Square } from './square'
import { Booker } from './booker'
import { Boulevard } from './boulevard'
import { Mindbody } from './mindbody'
import { Zenoti } from './zenoti'
import { Acuity } from './acuity'
import { PublicAcuity } from './public_acuity'
import { GlossGenius } from './glossgenius'

const bookingSytemNameToFormTab = {
  'Google Calendar': Google,
  'Vagaro': Google,
  'Square': Square,
  'Booker': Booker,
  'Boulevard': Boulevard,
  'Mindbody': Mindbody,
  'Zenoti': Zenoti,
  'Acuity (Powerhouse)': Acuity,
  'Acuity (Not Powerhouse)': PublicAcuity,
  'Gloss Genius': GlossGenius,
}

export const IntegrationFormTab = (props) => {
  const record = useRecordContext()
  const bookingSystemName = record?.bookingSystem?.name
  const BookingSystemFormTab = bookingSytemNameToFormTab[bookingSystemName]
  const VISIBLE_STATUSES = ['needAccess', 'readyToIntegrate', 'converted']

  if (!BookingSystemFormTab  || !VISIBLE_STATUSES.includes(record.status)){
    return null
  }

  return (
    <BookingSystemFormTab {...props} bookingSystemName={bookingSystemName}/>
  )
}
