import React from 'react';
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, List, Datagrid, TextField, DateField, FileField, DateInput } from 'react-admin';


export const UserEdit = (props) => {
  
    return (
    <Edit {...props}>
        <SimpleForm toolbar={
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        }>
            <TextInput disabled source="id" />
            <DateInput disabled label="Created At" source="createdAt" />
            <TextInput disabled label="First Name" source="firstName"/>
            <TextInput disabled label="Last Name" source="lastName" />
            <TextInput disabled label="Email" source="email" />
            <TextInput disabled label="Phone Number" source="phoneNumber" />
        </SimpleForm>
    </Edit>
)};

export const UserList = props => {
    return <List pagination={null} bulkActionButtons={false} {...props}>
        <Datagrid isRowSelectable={() => false} rowClick="edit">
            <TextField sortable={false} label="ID" source="id" />
            <DateField sortable={false} label="Created Date" source="createdAt"/>
            <TextField sortable={false} label="First Name" source="firstName" />
            <TextField sortable={false} label="Last Name" source="lastName" />
            <TextField sortable={false} label="Email Address" source="email"/>
            <TextField sortable={false} label="Phone Number" source="phoneNumber" />
            <FileField sortable={false} title="Image Link" source="imageUrl" target="_blank" label="Profile Photo"/>
        </Datagrid>
    </List>
}
