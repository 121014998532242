import {BookingSystemList} from './BookingSystemList';
import {BookingSystemShow} from './BookingSystemShow';
import {BookingSystemCreate} from './BookingSystemCreate';
import {BookingSystemEdit} from './BookingSystemEdit';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export default {
  list: BookingSystemList,
  edit: BookingSystemEdit,
  show: BookingSystemShow,
  create: BookingSystemCreate,
  icon: EventAvailableIcon
};
