import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab, Button } from 'react-admin';
import { useForm, useFormState } from 'react-final-form'
import { CircularProgress } from '@mui/material';

import { makeRequest } from '../../../../helpers'

export const GlossGenius = (props) => {
  const [providers, setProviders] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getStaffId = async ({form, values}) => {
    const integrationProviderSlug = values?.integrationDetails?.glossgenius?.integrationProviderSlug

    if (!integrationProviderSlug){
      alert('Fill out business name to get staff ID')
      return
    }

    setIsLoading(true)
    const staffIdResponse = await makeRequest('/glossgenius/staff_member', {integrationProviderSlug});
    setIsLoading(false)
    form.change('integrationDetails.glossgenius.integrationStaffId', staffIdResponse)
  };

  const StaffIdButton = () => {
    const form = useForm()
    const { values } = useFormState()

    return <>
      <Button disabled={isLoading} label="Get Staff Id" onClick={() => getStaffId({form, values})} />
      {isLoading ? <CircularProgress/> : null}
    </>
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.glossgenius.integrationProviderSlug" label="GlossGenius business name" helperText="https://<BUSINESS_NAME>.glossgenius.com"/>
      <StaffIdButton/>
      <TextInput disabled source="integrationDetails.glossgenius.integrationStaffId" label="GlossGenius staff id"/>
    </FormTab>
  );
};
