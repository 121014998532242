import React, { useState, useEffect } from 'react';
import {
  Datagrid,
  List,
  Responsive,
  SimpleList,
  TextField,
  BooleanField,
  DateField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NullableBooleanInput,
  AutocompleteArrayInput,
  Filter,
  Pagination
} from 'react-admin';
import { LEAD_STATUSES_LOOKUP, LEAD_STATUSES, ADMIN_OWNERS } from './enums';
import { makeRequest } from '../../helpers'

const StatusField = ({ record, source }) => {
  const status = record[source]
  return (<span>{LEAD_STATUSES_LOOKUP[status]}</span>);
};

const LeadFilter = ({neighborhoods, categories, subcategories, ...rest}) => (
  <Filter {...rest}>
      <NullableBooleanInput source="actionNeeded" />
      <ReferenceInput label="Booking System" source="bookingSystem" reference="booking_systems">
        <SelectInput optionValue="_id"/>
      </ReferenceInput>
      <AutocompleteArrayInput source="neighborhoods" choices={neighborhoods} allowEmpty={false} optionValue="_id" />
      <AutocompleteArrayInput source="categories" choices={categories} allowEmpty={false} optionValue="_id" />
      <AutocompleteArrayInput source="subcategories" choices={subcategories.map(s => ({...s, name: `${s.name} - ${s.category.name}`}))} allowEmpty={false} optionValue="_id" />
      <SelectInput source="status" choices={LEAD_STATUSES} alwaysOn/>
      <BooleanInput source="scheduledPhoneCall"/>
      <BooleanInput source="listedForFree"/>
      <SelectInput source="skoopAdminOwner" choices={ADMIN_OWNERS} />
  </Filter>
);


const LeadList = (props) => {
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    getNeighborhoods();
    getCategories();
    getSubcategories();
  }, []);

  const getNeighborhoods = async () => {
    const neighborhoodsResponse = await makeRequest('/neighborhoods');
    setNeighborhoods(neighborhoodsResponse);
  };

  const getCategories = async () => {
    const categoriesResponse = await makeRequest('/categories');
    setCategories(categoriesResponse);
  }

  const getSubcategories = async () => {
    const subcategoriesResponse = await makeRequest('/subcategories');
    setSubcategories(subcategoriesResponse);
  }

  return (
    <List {...props} 
      sort={{ field: 'name', order: 'asc' }}
      bulkActionButtons={false} 
      filters={<LeadFilter neighborhoods={neighborhoods} categories={categories} subcategories={subcategories}/>}
      filterDefaultValues={{ status: 'open' }}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 750]} />}
    >
      <Responsive
        small={
          <SimpleList linkType="show" primaryText={record => record.name} />
        }
        medium={
          <Datagrid rowClick="show">
            <TextField source="name" />
            <BooleanField sortable={false} source="actionNeeded" label="Sales action needed"/>
            <DateField label="Latest Note" source="lastNoteCreatedAt" />
            <TextField sortable={false} label="Booking System" source="bookingSystem.name" />
            <StatusField source="status"/>
          </Datagrid>
        }
      />
    </List>
  )
}

export default LeadList;
