import React, { useState, useEffect } from 'react';
import { SelectInput, FormTab } from 'react-admin';

import { CircularProgress } from '@mui/material';

import { makeRequest } from '../../../../helpers'

const isVagaroCalendar = (name) => {
  return name.includes('[Vagaro]')
}

const isAppScriptCalendar = (name) => {
  return name.includes('[Schedulista]') || name.includes('[Jane]') || name.includes('[Unified Practice]')
}

const getReadCalendarFilter = ({calendar, bookingSystemName}) => {
  if (bookingSystemName === 'Vagaro'){
    return calendar.accessRole === 'owner' && isVagaroCalendar(calendar.name)
  } else {
    return ['freeBusyReader', 'reader'].includes(calendar.accessRole) || isAppScriptCalendar(calendar.name)
  }
}

export const Google = (props) => {
  const [providers, setProviders] = useState([])
  const [calendarsLoading, setCalendarsLoading] = useState(true)
  const [readCalendars, setReadCalendars] = useState([])
  const [writeCalendars, setWriteCalendars] = useState([])

  useEffect(() => {
    getProviders();
    getGoogleCalendars();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getGoogleCalendars = async () => {
    const calendarsResponse = await makeRequest('/google_calendars');
    const readCalendarsResponse = calendarsResponse.filter( calendar => getReadCalendarFilter({calendar, bookingSystemName: props.bookingSystemName}))
    setReadCalendars(readCalendarsResponse);
    setWriteCalendars(calendarsResponse.filter( calendar => calendar.accessRole === 'owner' && !isAppScriptCalendar(calendar.name) && !isVagaroCalendar(calendar.name)));
    setCalendarsLoading(false);
  };

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      {calendarsLoading ? <>
        <CircularProgress/>
        <span>Loading calendar list via Google Calendar API</span>
      </> : null}
      <SelectInput disabled={calendarsLoading} source="integrationDetails.google.readCalendarId" label="Choose a read calendar" choices={readCalendars}/>
      <SelectInput disabled={calendarsLoading} source="integrationDetails.google.writeCalendarId" label="Choose a write calendar" choices={writeCalendars}/>
    </FormTab>
  );
};
