import { useFormState } from 'react-final-form';
import sortBy from 'lodash.sortby'
import { AutocompleteObjectArrayInput } from '../../../components/AutocompleteObjectArrayInput'

export const SubcategoriesInput = ({subcategories}) => {
  const { values } = useFormState();

  let choices = subcategories && subcategories.filter(subcategory => values.categories && !!values.categories.find(category => subcategory.category._id === category._id))

  if (values.categories && values.categories.length > 1){
      choices = choices.map(choice => ({...choice, name: `${choice.category.name} - ${choice.name}`}))
      choices = sortBy(choices, 'name')
  }

  return (
      <AutocompleteObjectArrayInput
          source="subcategories"
          optionValue="_id"
          choices={choices}
      />
  )
}