import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import MyDataProvider from './dataProvider';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CategoryIcon from '@material-ui/icons/Category';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FlareIcon from '@material-ui/icons/Flare';
import InfoIcon from '@material-ui/icons/Info';
import { AppointmentList, AppointmentEdit } from './models/appointments'
import { UserEdit, UserList } from './users'
import { ServiceList, ServiceEdit, ServiceCreate } from './services'
import { ServiceIncompleteList } from './services_incomplete'
import { CategoryList, CategoryEdit } from './categories'
import { SubcategoryList, SubcategoryEdit, SubcategoryCreate } from './subcategories'
import leads from './models/leads'
import leadNotes from './models/lead_notes'
import neighborhoods from './models/neighborhoods'
import bookingSystems from './models/booking_systems'
import referrals from './models/referrals'
import curatedLists from './models/curated_lists'
import providers from './models/providers'
import reviews from './models/reviews'
import providerSchedules from './models/provider_schedules'
import giftCards from './models/gift_cards'
import skoopPlusMemberships from './models/skoop_plus_memberships'

import authProvider from './authProvider';
import { API_URL } from './environment'

const httpClient = (url, options = {}) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  const token = auth ? auth.token : null
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json', authorization: token });
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = MyDataProvider(API_URL, httpClient);

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="appointments" list={AppointmentList} edit={AppointmentEdit} icon={DateRangeIcon}/>
        <Resource name="users" list={UserList} edit={UserEdit} icon={GroupAddIcon}/>
        <Resource name="providers" {...providers} />
        <Resource name="services" list={ServiceList} edit={ServiceEdit} icon={FlareIcon} create={ServiceCreate}/>
        <Resource options={{label: "Incomplete Services"}} name="admin/incomplete_services" list={ServiceIncompleteList} icon={InfoIcon}/>
        <Resource name="categories" list={CategoryList} edit={CategoryEdit} icon={CategoryIcon} />
        <Resource name="subcategories" list={SubcategoryList} icon={DynamicFeedIcon} edit={SubcategoryEdit}/>
        <Resource name="leads" {...leads}/>
        <Resource name="lead_notes" {...leadNotes}/>
        <Resource name="neighborhoods" {...neighborhoods}/>
        <Resource name="referrals" {...referrals}/>
        <Resource name="booking_systems" {...bookingSystems}/>
        <Resource name="curated_lists" {...curatedLists}/>
        <Resource name="reviews" {...reviews}/>
        <Resource name="gift_cards" {...giftCards}/>
        <Resource name="skoop_plus_memberships" options={{ label: 'Skoop+' }} {...skoopPlusMemberships}/>
        <Resource name="provider_schedules" {...providerSchedules}/>
    </Admin>
);
export default App;
