import React from 'react';
import {
  Datagrid,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  ShowButton,
  EditButton,
  TopToolbar,
  DateField,
  BooleanField
} from 'react-admin';
import { ChipField, SingleFieldList, ArrayField } from 'react-admin';
import AddNoteButton from './AddNoteButton';
import { LEAD_STATUSES_LOOKUP, ADMIN_OWNERS_LOOKUP, LEAD_NOTE_TYPES_LOOKUP, OUTREACH_TYPES_LOOKUP } from './enums';
import { IntegrationTab } from './integration_details/IntegrationTab'

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
      <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const Title = ({record}) => <span>{record.name}</span>

const StatusField = ({ record, source }) => {
  const status = record[source]
  return (<span>{LEAD_STATUSES_LOOKUP[status]}</span>);
};
StatusField.defaultProps = { label: 'Status', addLabel: true };

const SkoopAdminOwnerField = ({ record, source }) => {
  const owner = record[source]
  return (<span>{ADMIN_OWNERS_LOOKUP[owner]}</span>);
};
SkoopAdminOwnerField.defaultProps = { label: 'Skoop Admin Owner', addLabel: true };

const leadNotePrimaryText = (record) => {
  const noteType = record.noteType || 'genericNote'
  let text = `${LEAD_NOTE_TYPES_LOOKUP[noteType]}: `
  switch(noteType) {
    case 'followUp':
      text = text + record.body
      break
    case 'genericNote':
      text = text + record.body
      break
    case 'scheduledPhoneCall':
      text = text + record.phoneCallDate
      break
    case 'salesTemplateOutreach':
      text = text + `${OUTREACH_TYPES_LOOKUP[record.outreachType]} on ${record.outreachDate}`
      break
  }
  return text
}

const LeadNotePrimaryField = ({record}) => (
  <span>{leadNotePrimaryText(record)}</span>
)

const LeadShow = (props) => {
return(
  <Show {...props} actions={<Actions />} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <TextField label="Booking System" source="bookingSystem.name" />
        <ArrayField source="neighborhoods">
          <SingleFieldList linkType={false}>
              <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="categories">
          <SingleFieldList linkType={false}>
              <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="subcategories">
          <SingleFieldList linkType={false}>
              <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <StatusField source="status"/>
        <BooleanField source="listedForFree"/>
        <SkoopAdminOwnerField source="skoopAdminOwner"/>
      </Tab>
      <Tab label="Primary Contact" path="primary_contact">
        <TextField source="primaryContactFirstName" label="First Name" />
        <TextField source="primaryContactLastName" label="Last Name"/>
        <TextField source="primaryContactEmail" label="Email"/>
        <TextField source="primaryContactPhoneNumber" label="Phone Number"/>
        <TextField source="primaryContactRole" label="Role"/>
      </Tab>
      <Tab label="Profile Info" path="profile_info">
        <TextField source="description" />
        <TextField source="website" />
        <TextField source="instagram" />
      </Tab>
      <IntegrationTab/>
      <Tab label="Notes" path="lead_notes">
        <ReferenceManyField
          addLabel={false}
          reference="lead_notes"
          target="leadId"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid>
            <DateField source="createdAt" showTime />
            <LeadNotePrimaryField label="Note details"/>
            <TextField sortable={false} label="Author" source="author.fullName" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <AddNoteButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
)};

export default LeadShow;
