import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  DateField,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';


export const SkoopPlusList = (props) => {
  const Actions = () => {
    const listContext = useListContext()
    const activeMembers = listContext.ids.filter(id => !listContext.data[id].cancelDate)
    return <TopToolbar>{activeMembers.length} members = ${activeMembers.length * 10 * 12}/yr</TopToolbar>
  }

  return (
  <List {...props} sort={{ field: 'createdAt', order: 'asc' }} bulkActionButtons={false} pagination={null} actions={<Actions/>}>
    <Responsive
      small={
        <Datagrid>
          <DateField sortable={false} label="Started" source="createdAt" />
          <TextField sortable={false} label="email" source="user.email" />
          <TextField sortable={false} label="name" source="user.fullName" />
          <DateField sortable={false} label="Cancelled" source="cancelDate" />
        </Datagrid>
      }
    />
  </List>
)}
