import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab, Button } from 'react-admin';
import { useFormState, useForm } from 'react-final-form'

import { makeRequest } from '../../../../helpers'

export const Mindbody = (props) => {
  const [providers, setProviders] = useState([])
  const [mindbodyLocations, setMindbodyLocations] = useState([])

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getLocations = async (siteId) => {
    try {
      const locationsResponse = await makeRequest('/mindbody/locations', {siteId});
      setMindbodyLocations(locationsResponse)
    } catch (err){
      alert(err)
      return
    }
  };

  const ShowLocationsButton = () => {
    const { values } = useFormState()
    const siteId = values.integrationDetails?.mindbody?.siteId

    if (!siteId){
      return null
    }

    return <Button label="Get Locations" onClick={() => getLocations(siteId)} />
  }

  const getSiteId = async ({form, values}) => {
    const slug = values?.integrationDetails?.mindbody?.slug

    if (!slug){
      alert('Fill out slug to get site id')
      return
    }

    try {
      const siteIdResponse = await makeRequest('/mindbody/site_id', {slug});
      form.change('integrationDetails.mindbody.siteId', siteIdResponse)
    } catch (err){
      alert(err)
    }
  };

  const SiteIdButton = () => {
    const form = useForm()
    const { values } = useFormState()

    return <Button label="Get Site Id" onClick={() => getSiteId({form, values})} />
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.mindbody.slug" label="Mindbody Slug" helperText="https://www.mindbodyonline.com/explore/locations/<SLUG>"/>
      <SiteIdButton />
      <TextInput disabled source="integrationDetails.mindbody.siteId" label="Mindbody Site ID"/>
      <ShowLocationsButton/>
      {
        mindbodyLocations.length ? 
        <SelectInput source="integrationDetails.mindbody.locationId" optionText="Name" optionValue="Id" label="Mindbody Location" choices={mindbodyLocations}/>
        : null
      }
    </FormTab>
  );
};
