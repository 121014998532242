import React from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { BOOKING_SYSTEM_TYPES, TWO_WAY_OPEN_API } from './enums'
import { useFormState } from 'react-final-form';

const BottomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const Title = ({record}) => <span>{record.name}</span>

const ApiDocumentationUrl = () => {
  const { values } = useFormState()
  if (values.type === TWO_WAY_OPEN_API){
    return <TextInput source="apiDocumentationUrl" />
  }
  return null
}

export const BookingSystemEdit = props => (
  <Edit {...props} title={<Title/>}>
    <SimpleForm toolbar={<BottomToolbar/>}>
      <TextInput source="name" />
      <BooleanInput source="isLive" label="Is Live on Skoop" />
      <SelectInput source="type" choices={BOOKING_SYSTEM_TYPES}/>
      <ApiDocumentationUrl/>
      <TextInput style={{width: '100%'}} multiline source="providerOnboardingInstructions" />
    </SimpleForm>
  </Edit>
);
