const _getNewFieldValue = ({fieldToOverwrite, mediaUrls, s3Url}) => {
  const updatedImageObject = { src: s3Url, rawFile: {} } // rawFile property kept for <ImageField> internal delete method to work properly 
  if (fieldToOverwrite === 'mediaUrlsWithSrc'){
    const existingMediaUrls = mediaUrls.slice(0, mediaUrls.length - 1)
    return existingMediaUrls.concat(updatedImageObject)
  } else if (fieldToOverwrite === 'thumbnailWithSrc'){
    return updatedImageObject
  }
}

export const optionallyReplaceBlobWithS3Url = ({values, s3Url, replaceBlobWithS3Url}) => {
  const { mediaUrlsWithSrc: mediaUrls, thumbnailWithSrc: thumbnail } = values
  const hasFileBeenUploaded = !!s3Url
  if (!hasFileBeenUploaded){
    return
  }

  const areMediaUrlsUpdated = !!mediaUrls.find( m => m.src.includes('blob') )
  const isThumbnailUpdated = thumbnail?.src.includes('blob')

  if (!areMediaUrlsUpdated && !isThumbnailUpdated){
    // unable to disable ImageInput so this alert is hacky solution
    alert('Unexpected state. Do not delete or upload files until the previous file has been uploaded.  Refresh your browser to avoid saving corrupted data.')
    return
  }

  const fieldToOverwrite = areMediaUrlsUpdated ? 'mediaUrlsWithSrc' : 'thumbnailWithSrc'
  const newValue = _getNewFieldValue({fieldToOverwrite, mediaUrls, s3Url})

  replaceBlobWithS3Url({fieldToOverwrite, newValue})
}
