export const LEAD_STATUSES = [
  {id: 'open', name: 'Open'},
  {id: 'sentTerms', name: 'Sent Terms'},
  {id: 'verballyAgreed', name: 'Verbally Agreed'},
  {id: 'needAccess', name: 'Signed, Need Access'},
  {id: 'buildIntegration', name: 'Build Integration'},
  {id: 'readyToIntegrate', name: 'Ready To Integrate'},
  {id: 'converted', name: 'Converted (Live)'},
  {id: 'conciergeOnly', name: 'Concierge Only'},
  {id: 'firstTimeReferralOnly', name: 'First-Time Referral Only'},
  {id: 'unresponsive', name: 'No Response'},
  {id: 'ghosted', name: 'Ghosted'},
  {id: 'pass', name: 'Pass'},
]

const buildLookup = (arrayOfObjects) => {
  let lookup = {}
  arrayOfObjects.forEach(object => lookup[object.id] = object.name) 
  return lookup
}

export const LEAD_STATUSES_LOOKUP = buildLookup(LEAD_STATUSES)

export const ADMIN_OWNERS = [
  {id: '5fb514496023970017789a61', name: 'Riley'},
  {id: '5fb7c75a43eed1001718f146', name: 'Kristen'},
  {id: '60a7079598f40d0017a3667e', name: 'Madolyn'},
]

export const ADMIN_OWNERS_LOOKUP = buildLookup(ADMIN_OWNERS)

export const OUTREACH_TYPES = [
  {id: 'email1', name: 'Email #1'},
  {id: 'dm1', name: 'DM'},
  {id: 'call1', name: 'Cold Call'},
  {id: 'email2', name: 'Email #2'},
  {id: 'email3', name: 'Email #3'},
  {id: 'visit1', name: 'In-Person Visit'},
  {id: 'email4', name: 'Email #4'},
  // call2 no longer in use
  {id: 'call2', name: 'Call #2'},
]

export const LEAD_NOTE_TYPES = [
  {id: 'followUp', name: '(Signed/Sent Terms) Follow Up'},
  {id: 'salesTemplateOutreach', name: 'Sales Template Outreach'},
  {id: 'scheduledPhoneCall', name: 'Scheduled Phone Call'},
  {id: 'genericNote', name: 'Generic Note'},
]

export const OUTREACH_TYPES_LOOKUP = buildLookup(OUTREACH_TYPES)
export const LEAD_NOTE_TYPES_LOOKUP = buildLookup(LEAD_NOTE_TYPES)

