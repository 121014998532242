import React, { useState, useEffect } from 'react';
import {
  Edit,
  TextInput,
  Toolbar,
  SaveButton,
  TabbedForm,
  FormTab,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  required
} from 'react-admin';
import { makeRequest } from '../../helpers'
import { SubcategoriesInput } from './inputs/SubcategoriesInput';
import { AutocompleteObjectArrayInput } from '../../components/AutocompleteObjectArrayInput'
import { LEAD_STATUSES, ADMIN_OWNERS } from './enums';
import { IntegrationFormTab } from './integration_details/IntegrationFormTab'
import { ConvertButton } from './ConvertButton'

const BottomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.pristine} />
      <ConvertButton/>
  </Toolbar>
);

const Title = ({record}) => <span>{record.name}</span>

export const LeadEdit = (props) => {
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    getNeighborhoods();
    getCategories();
    getSubcategories();
  }, []);

  const getNeighborhoods = async () => {
    const neighborhoodsResponse = await makeRequest('/neighborhoods');
    setNeighborhoods(neighborhoodsResponse);
  };

  const getCategories = async () => {
    const categoriesResponse = await makeRequest('/categories');
    setCategories(categoriesResponse);
  }

  const getSubcategories = async () => {
    const subcategoriesResponse = await makeRequest('/subcategories');
    setSubcategories(subcategoriesResponse);
  };


  return (
    <Edit {...props} title={<Title/>}>
      <TabbedForm toolbar={<BottomToolbar />}>
        <FormTab label="Summary">
          <TextInput source="name" validate={required()}/>
          <ReferenceInput allowEmpty label="Booking System" source="bookingSystem._id" reference="booking_systems">
            <SelectInput optionValue="_id"/>
          </ReferenceInput>
          <AutocompleteObjectArrayInput source="neighborhoods" choices={neighborhoods} optionValue="_id" />
          <AutocompleteObjectArrayInput source="categories" choices={categories} optionValue="_id" />
          <SubcategoriesInput subcategories={subcategories}/>
          <SelectInput source="status" choices={LEAD_STATUSES}/>
          <BooleanInput source="listedForFree"/>
          <SelectInput source="skoopAdminOwner" choices={ADMIN_OWNERS} />
        </FormTab>
        <FormTab label="Primary Contact" path="primary_contact">
          <TextInput source="primaryContactFirstName" label="First Name" />
          <TextInput source="primaryContactLastName" label="Last Name"/>
          <TextInput source="primaryContactEmail" label="Email"/>
          <TextInput source="primaryContactPhoneNumber" label="Phone Number"/>
          <TextInput source="primaryContactRole" label="Role"/>
        </FormTab>
        <FormTab label="Profile Info" path="profile_info">
          <TextInput multiline source="description" />
          <TextInput source="website" />
          <TextInput source="instagram" />
        </FormTab>
        <IntegrationFormTab/>
      </TabbedForm>
    </Edit>
  );
};
