import React from 'react';
import {
  Edit,
  DateInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  required
} from 'react-admin';
import { OUTREACH_TYPES, LEAD_NOTE_TYPES } from '../leads/enums';
import { useFormState } from 'react-final-form';

const LeadNoteEdit = props => {

  const GenericNoteSection = () => (
    <TextInput disabled multiline source="body"/>
  )

  const ScheduledPhoneCallSection = () => (
    <>
      <DateInput source="phoneCallDate" label="Date of Phone Call" validate={required()}/>
      <TextInput disabled multiline source="phoneCallNote" />
    </>
  )

  const OutreachSection = () => (
    <>
      <DateInput disabled source="outreachDate" label="Date of Outreach" validate={required()}/>
      <SelectInput disabled source="outreachType" choices={OUTREACH_TYPES} validate={required()}/>
      <BooleanInput disabled source="outreachPhoneCallConnection" label="Talked to a stakeholder on the phone?"/>
      <TextInput disabled multiline source="outreachNote" />
    </>
  )

  const NoteTypeSections = () => {
    const { values } = useFormState()
    const noteType = values.noteType || 'genericNote'

    switch(noteType) {
      case 'followUp':
        return <GenericNoteSection />
      case 'genericNote':
        return <GenericNoteSection />
      case 'scheduledPhoneCall':
        return <ScheduledPhoneCallSection />
      case 'salesTemplateOutreach':
        return <OutreachSection />
      default:
        return null
    }
  }


  const BottomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
    </Toolbar>
  );

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<BottomToolbar/>}>
        <ReferenceInput
          source="leadId"
          reference="leads"
          disabled={true}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput disabled source="createdAt" showTime />
        <TextInput disabled label="Author" source="author.fullName" />

        {/* <SelectInput disabled source="noteType" choices={LEAD_NOTE_TYPES} validate={required()} /> */}
        <NoteTypeSections />

      </SimpleForm>
    </Edit>
  );
};

export default LeadNoteEdit;
