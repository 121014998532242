import React  from 'react';
import { FormTab } from 'react-admin';
import GoogleSection from './GoogleSection'
import YelpSection from './YelpSection'

export const REACT_JSON_DEFAULT_PROPS = { style: {marginBottom: 20}, displayDataTypes: false, displayObjectSize: false, displayArrayKey: false, collapsed: true }

// https://github.com/marmelab/react-admin/issues/3762#issuecomment-537467252 make sure to propagate props!
export default (props) => {
  return (
    <FormTab label="Google And Yelp" {...props}>
      <YelpSection className="w_50" style={{'margin-top': 20}}/>
      <GoogleSection className="w_50" style={{'margin-top': 20}}/>
    </FormTab>
  );
};
