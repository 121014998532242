import React from 'react';
import { Tab, TextField, useRecordContext } from 'react-admin';

const INTEGRATION_FIELDS_FOR_BOOKING_SYSTEM = {
  'Google Calendar': [
    <TextField key={0} label="Provider Id" source="provider" />,
    <TextField key={1} label="Read Calendar Id" source="integrationDetails.google.readCalendarId" />,
    <TextField key={2} label="Write Calendar Id" source="integrationDetails.google.writeCalendarId" />
  ],
  'Vagaro': [
    <TextField key={0} label="Provider Id" source="provider" />,
    <TextField key={1} label="Read Calendar Id" source="integrationDetails.google.readCalendarId" />,
    <TextField key={2} label="Write Calendar Id" source="integrationDetails.google.writeCalendarId" />
  ],
  'Square': [
    <TextField key={0} label="Provider Id" source="provider" />,
    <TextField key={1} label="Google Write Calendar Id" source="integrationDetails.google.writeCalendarId" />,
    <TextField key={2} label="Square Credential Set" source="integrationDetails.square.squareCredentialSet" />,
    <TextField key={3} label="Square Booking Link" source="integrationDetails.square.bookingLink" />,
    <TextField key={4} label="Square Business Id" source="integrationDetails.square.integrationBusinessId" />,
    <TextField key={5} label="Square Location Id" source="integrationDetails.square.integrationLocationId" />,
  ],
  'Booker': [
    <TextField key={0} label="Provider Id" source="provider" />,
    <TextField key={1} label="Booker Account Name" source="integrationDetails.booker.accountName" />,
    <TextField key={2} label="Booker Location Id" source="integrationDetails.booker.integrationLocationId" />,
  ],
  'Boulevard': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="Boulevard Business ID" source="integrationDetails.boulevard.businessId" />,
    <TextField key={2} label="Boulevard Location ID" source="integrationDetails.boulevard.locationId" />,
  ],
  'Mindbody': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="Mindbody Site ID" source="integrationDetails.mindbody.siteId" />,
    <TextField key={2} label="Mindbody Location ID" source="integrationDetails.mindbody.locationId" />,
  ],
  'Zenoti': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="Zenoti API Key" source="integrationDetails.zenoti.apiKey" />,
    <TextField key={2} label="Zenoti Center ID" source="integrationDetails.zenoti.centerId" />,
  ],
  'Acuity (Powerhouse)': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="Acuity API Key" source="integrationDetails.acuity.apiKey" />,
    <TextField key={2} label="Acuity User ID" source="integrationDetails.acuity.userId" />,
  ],
  'Acuity (Not Powerhouse)': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="Google Write Calendar Id" source="integrationDetails.google.writeCalendarId" />,
    <TextField key={2} label="Acuity Owner" source="integrationDetails.public_acuity.integrationProviderId" />,
  ],
  'Gloss Genius': [
    <TextField key={0} label="Provider ID" source="provider" />,
    <TextField key={1} label="GlossGenius Subdomain" source="integrationDetails.glossgenius.integrationProviderSlug" />,
    <TextField key={2} label="GlossGenius Staff ID" source="integrationDetails.glossgenius.integrationStaffId" />,
  ],
}

export const IntegrationTab = (props) => {
  const record = useRecordContext()
  const VISIBLE_STATUSES = ['needAccess', 'readyToIntegrate', 'converted']

  const integrationFieldComponents = INTEGRATION_FIELDS_FOR_BOOKING_SYSTEM[record?.bookingSystem?.name]

  if (!integrationFieldComponents || !VISIBLE_STATUSES.includes(record.status)){
    return null
  }

  return (
    <Tab {...props} label="Integration Details" path="integration">
      {integrationFieldComponents}
    </Tab>
  )
}
