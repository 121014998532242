import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  DateField,
  TextField,
} from 'react-admin';
import moment from 'moment-timezone'

const SendDate = ({ record }) => {
  let sendDate
  if (record.waitToSend){
    sendDate = record.sendDate
  } else if (record.giftCardCode){
    sendDate = record.redeemedAt ? `Redeemed ${moment(record.redeemedAt).format('YYYY-MM-DD')}` : 'Not Yet Redeemed'
  } else {
    sendDate = 'Instant'
  }
  return (<span>{sendDate}</span>);
};

const Recipient = ({ record }) => {
  const recipient = record.receiverEmail || record.receiver?.email
  return (<span>{recipient}</span>);
};

const GiftCardType = ({ record }) => {
  const giftCardType = record.giftCardCode ? 'Square' : 'Skoop'
  return (<span>{giftCardType}</span>);
};

export const GiftCardList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'asc' }} bulkActionButtons={false} pagination={null}>
    <Responsive
      small={
        <Datagrid>
          <DateField sortable={false} label="Purchased on" source="createdAt" />
          <GiftCardType label="Type"/>
          <SendDate label="Sent or Redeemed"/>
          <TextField sortable={false} label="Sender" source="sender.email" />
          <Recipient label="Recipient"/>
          <TextField sortable={false} source="amount" />
          <TextField sortable={false} source="remainingBalance" />
        </Datagrid>
      }
    />
  </List>
)

