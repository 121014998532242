import { NeighborhoodList } from './NeighborhoodList';
import { NeighborhoodCreate } from './NeighborhoodCreate';
import { NeighborhoodEdit } from './NeighborhoodEdit';
import HouseIcon from '@mui/icons-material/House';

export default {
  list: NeighborhoodList,
  edit: NeighborhoodEdit,
  create: NeighborhoodCreate,
  icon: HouseIcon
};
