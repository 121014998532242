import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, NumberInput, BooleanInput, required, ArrayInput, SimpleFormIterator } from 'react-admin';

export const CategoryEdit = (props) => {

    return (
    <Edit {...props}>
        <SimpleForm toolbar={
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        }>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <NumberInput source="ordinality" />
            <ArrayInput source="searchTerms" style={{marginBottom: 60}}>
                <SimpleFormIterator>
                    <TextInput label="" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>
            {/* <b>WARNING - this will remove this category and ALL of its subcategories from every service on Skoop</b>
            <BooleanInput label="Archived?" source="archived" /> */}
        </SimpleForm>
    </Edit>
)};

export const CategoryList = props => (
    <List actions={null} pagination={null} bulkActionButtons={false} {...props}>
        <Datagrid isRowSelectable={() => false} rowClick="edit">
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="name" />
            <TextField sortable={false} source="ordinality" />
        </Datagrid>
    </List>
);
