import React, { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  Button,
  required
} from 'react-admin';
import { makeRequest } from '../../helpers'
import { useFormState, useForm } from 'react-final-form';
import { prepend, SubcategoryInput } from '../../components/SubcategoryInput'

export default (props) => {
  const [providers, setProviders] = useState([])
  const [providerServices, setProviderServices] = useState([])
  const [listServices, setListServices] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  useEffect(() => {
    getProviders();
    getCategories()
    getSubcategories()
  }, []);

  const getCategories = async () => {
      const categoriesResponse = await makeRequest('/categories', {isSearchable: true})
      setCategories(categoriesResponse)
  }

  const getSubcategories = async () => {
      const subcategoriesResponse = await makeRequest('/subcategories', {isCurrent: true})
      setSubcategories(subcategoriesResponse)
  }

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getProviderServices = async (selectedProviderId) => {
    const servicesResponse = await makeRequest(`/services?provider=${selectedProviderId}`);
    setProviderServices(servicesResponse);
  };

  const getListServices = async (serviceIds) => {
    const servicesResponse = await makeRequest(`/services?serviceIds=${serviceIds}`);
    setListServices(servicesResponse);
  };

  const handleProviderSelect = (selectedProviderId) => {
    if (selectedProviderId){
      getProviderServices(selectedProviderId)
    }
  }

  const AddServiceToCuratedListButton = () => {
    const { values } = useFormState();
    const { change, batch } = useForm();

    const addServiceToCuratedList = () => {
      const updatedServiceIds = (values.serviceIds || []).concat(values.selectedService)
      batch(() => {
        change('selectedProvider', undefined)
        change('selectedService', undefined)
        change('serviceIds', updatedServiceIds)
      })
      getListServices(updatedServiceIds)
    }

    return (
      <Button disabled={!values.selectedService} label="+ Add Service" onClick={addServiceToCuratedList}/>
    );
  };

  return (
    <>
      <Create {...props}>
        <SimpleForm>
          <TextInput source="title" validate={required()}/>
          <BooleanInput source="active" label="Active"/>
          <AutocompleteInput disabled={categories.length === 0} source="category" choices={prepend({_id: null, name: ''}, categories)} optionValue="_id" emptyValue={null} emptyText=''/>
          <SubcategoryInput subcategories={subcategories}/>
          <BooleanInput source="inHomeFilter" label="In-Home Only"/>
          <BooleanInput source="hasDiscountFilter" label="Skoop Exclusive"/>
          <NumberInput source="sortOrder" label="Sort Order"/>
          <SelectInput source="selectedProvider" label="Choose a provider" choices={providers} optionValue="_id" optionText={(record) => `${record.name} (${record.neighborhood})`} onChange={e => handleProviderSelect(e.target.value)}/>
          <SelectInput source="selectedService" label="Choose a service" choices={providerServices} optionValue="_id"/>
          <AddServiceToCuratedListButton/>
          <AutocompleteArrayInput source="serviceIds" label="Services" choices={listServices} optionValue="_id" optionText="providerAndServiceName" />
        </SimpleForm>
      </Create>
    </>
  );
};
