import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';
import { TextFieldPreserveNewlines } from '../../components/TextFieldPreserveNewlines'


export const ReviewShow = (props) => {
return(
  <Show {...props}>
    <SimpleShowLayout>
      <TextFieldPreserveNewlines multiline source="text" />
      <TextField label='User' source="appointment.user.fullName" />
      <TextField label='Provider' source="appointment.provider.name" />
      <TextField label='Neighborhood' source="appointment.provider.neighborhood" />
      <TextField label='Service' source="appointment.service.name" />
      <TextField source="overall" />
      <TextField source="quality" />
      <TextField source="ambience" />
      <TextField source="expertise" />
      <TextField source="friendliness" />
      <TextField source="cleanliness" />
      <TextField source="value" />
      <DateField label='Appointment Date' source="appointment.dateTime" />
      <DateField label='Review Date' source="appointment.createdAt" />
    </SimpleShowLayout>
  </Show>
)};
