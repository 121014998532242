import List from './List';
import Show from './Show';
import Create from './Create';
import Edit from './Edit';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';

export default {
  list: List,
  edit: Edit,
  show: Show,
  create: Create,
  icon: AppShortcutIcon
};
