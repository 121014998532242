import React, {useEffect, useState} from 'react';
import { List, Datagrid, TextField, AutocompleteInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { Create, Edit, SimpleForm, TextInput, SaveButton, Toolbar, BooleanInput, required } from 'react-admin';
import { makeRequest } from './helpers'

export const SubcategoryEdit = (props) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async () => {
        const categoriesResponse = await makeRequest('/categories')
        setCategories(categoriesResponse)
    }

    return (
    <Edit {...props}>
        <SimpleForm toolbar={
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        }>
            <TextInput disabled source="id" />
            <AutocompleteInput source="category._id" choices={categories} optionValue="_id" validate={required()}/>
            <TextInput source="name" validate={required()}/>
            <TextInput multiline source="description" />
            <ArrayInput source="searchTerms" style={{marginBottom: 60}}>
                <SimpleFormIterator>
                    <TextInput label="" validate={required()}/>
                </SimpleFormIterator>
            </ArrayInput>

            {/* <b>WARNING - this will remove this subcategory from every service on Skoop</b>
            <BooleanInput label="Archived?" source="archived" /> */}
        </SimpleForm>
    </Edit>
)};

// export const SubcategoryCreate = (props) => {
//     const [categories, setCategories] = useState([])

//     useEffect(() => {
//         getCategories()
//     }, [])

//     const getCategories = async () => {
//         const categoriesResponse = await makeRequest('/categories')
//         setCategories(categoriesResponse)
//     }

//     return (
//     <Create {...props}>
//         <SimpleForm toolbar={
//             <Toolbar {...props} >
//                 <SaveButton />
//             </Toolbar>
//         }>
//             <AutocompleteInput source="category" choices={categories} optionValue="_id" validate={required()}/>
//             <TextInput label="New subcategory name" source="name" validate={required()}/>
//             <TextInput multiline source="description" />
//         </SimpleForm>
//     </Create>
// )};

export const SubcategoryList = props => (
    <List pagination={null} bulkActionButtons={false} {...props}>
        <Datagrid isRowSelectable={() => false} rowClick="edit">
            <TextField sortable={false} source="id" />
            <TextField sortable={false} source="name" />
            <TextField sortable={false} label="category" source="category.name" />
        </Datagrid>
    </List>
);
