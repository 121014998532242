import React, { useState, useEffect } from 'react';
import { SelectInput, FormTab, TextInput, regex, Labeled } from 'react-admin';
import { useFormState } from 'react-final-form'
import { CircularProgress } from '@mui/material';
import { makeRequest } from '../../../../helpers'

const SQUARE_BASE_URL_REGEX = "^https://squareup.com/appointments/book/"

export const Square = (props) => {
  const [providers, setProviders] = useState([])
  const [credentialSets, setCredentialSets] = useState([])
  const [calendarsLoading, setCalendarsLoading] = useState(true)
  const [writeCalendars, setWriteCalendars] = useState([])

  useEffect(() => {
    getProviders();
    getCredentialSets();
    getGoogleCalendars();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getCredentialSets = async () => {
    const credentialSetsResponse = await makeRequest('/square_credential_sets');
    setCredentialSets(credentialSetsResponse)
  };

  const getGoogleCalendars = async () => {
    const calendarsResponse = await makeRequest('/google_calendars');
    setWriteCalendars(calendarsResponse.filter( calendar => calendar.accessRole === 'owner'));
    setCalendarsLoading(false);
  };

  const ParseBusinessIdFromBookingLink = ({record}) => {
    const { values } = useFormState()
    const link = values?.integrationDetails?.square?.bookingLink || record?.integrationDetails?.square?.bookingLink
    if (!link){
      return null
    }
    const path = link.replace('https://squareup.com/appointments/book/', '')
    const businessId = path.slice(0, path.indexOf(`/`))
    return <Labeled label="Business Id"><span>{businessId}</span></Labeled>
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <SelectInput source="integrationDetails.square.squareCredentialSet" label="Select Square business" choices={credentialSets}  optionValue="_id" optionText="businessName"/>
      <TextInput
        source="integrationDetails.square.bookingLink"
        label="Square booking link"
        helperText="(https://squareup.com/appointments/book/...)"
        validate={regex(new RegExp(SQUARE_BASE_URL_REGEX), 'Not a valid Square booking link')}
      />
      <ParseBusinessIdFromBookingLink/>
      {calendarsLoading ? <>
        <CircularProgress/>
        <span>Loading calendar list via Google Calendar API</span>
      </> : null}
      <SelectInput disabled={calendarsLoading} source="integrationDetails.google.writeCalendarId" label="Choose a write calendar" choices={writeCalendars}/>
    </FormTab>
  );
};
