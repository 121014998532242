import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import { parseBookingSystem } from './util/parseBookingSystem'


const BookingSystemField = ({ provider }) => {
  return (
    <span>{parseBookingSystem(provider)}</span>
  );
};

export default (props) => (
  <List pagination={null} bulkActionButtons={false} {...props}>
    <Datagrid isRowSelectable={() => false} rowClick="edit">
      <TextField sortable={false} source="id" />
      <TextField sortable={false} source="name" />
      <TextField sortable={false} source="neighborhood" />
      <FunctionField
          label="Booking System"
          render={ provider => ( <BookingSystemField provider={provider}/> ) }
        />
      <TextField sortable={false} source="address" />
    </Datagrid>
  </List>
);
