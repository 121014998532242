import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput
} from 'react-admin';

import { BOOKING_SYSTEM_TYPES, TWO_WAY_OPEN_API } from './enums'
import { useFormState } from 'react-final-form';

const ApiDocumentationUrl = () => {
  const { values } = useFormState()
  if (values.type === TWO_WAY_OPEN_API){
    return <TextInput source="apiDocumentationUrl" />
  }
  return null
}

export const BookingSystemCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput source="isLive" label="Is Live on Skoop"/>
        <SelectInput source="type" choices={BOOKING_SYSTEM_TYPES}/>
        <ApiDocumentationUrl/>
        <TextInput multiline source="providerOnboardingInstructions" />
      </SimpleForm>
    </Create>
  );
};
