import LeadList from './LeadList';
import LeadShow from './LeadShow';
import {LeadCreate} from './LeadCreate';
import {LeadEdit} from './LeadEdit';

export default {
  list: LeadList,
  edit: LeadEdit,
  show: LeadShow,
  create: LeadCreate,
};
