import React from 'react';
import { Link } from 'react-router-dom';

export default ({record}) => {
  if (!record.providerSchedule){
    return null
  }
  return (
    <Link to={{ pathname: `/provider_schedules/${record.providerSchedule._id}` }}>
      Provider Schedule
    </Link>
  )
}
