import {
  required,
  regex,
  maxValue,
  minValue,
} from 'react-admin';
import get from 'lodash.get'

export const requiredRange = (min, max) => {
  return [
    required(),
    maxValue(max, `No more than ${max}`),
    minValue(min, `No less than ${min}`),
  ];
};

export const validateWebsite = (message = 'Website must begin with https:// or http:// and no trailing slash') =>
    value => 
      !value || 
      (
        (value.slice(0,8) === 'https://' || value.slice(0,7) === 'http://')
        &&
        value.slice(-1) !== '/'
      )
      ? undefined : message;

export const validateInstagram = (message = 'Instagram must begin with https://www.instagram.com/ and no trailing URL params or slashes') =>
    value => {
      return (
        !value 
        || 
        (
          value.slice(0,26) === 'https://www.instagram.com/'
          &&
          value.length > 26
          &&
          ( 
            value.slice(26).indexOf('/') === -1
            ||
            value.length < value.slice(26).indexOf('/') + 26
          )
        )
        ? undefined : message
      )
    }

export const validateTime = regex(/^[0-2][0-9]:[0-5][0-9]$/, 'Make sure time is in format 00:00 (with leading zero) e.g. 9:00am is 09:00')
export const validatePMTime = (message='Closing time must be after 12:00') => (value) => {
  return !value || value > '11:59' ? undefined : message
}
export const validateOpenAndClose = () => (value, allValues, props) => {
  const field = props.name
  const relatedField = field.includes('end') ? field.replace('end', 'start') : field.replace('start', 'end')
  const relatedFieldValue = get(allValues, relatedField)

  if (value && !relatedFieldValue || !value && relatedFieldValue){
    return 'Fill out both open and close for a given day, or leave them both blank'
  }
  const start = field.includes('start') ? value : relatedFieldValue
  const end = field.includes('end') ? value : relatedFieldValue
  if (end <= start){
    return 'Open must be before close'
  }

  return undefined
}
