import React, { useState } from 'react';
import { Button } from 'react-admin';
import { parseBookingSystem } from './util/parseBookingSystem';
import { AddLocation } from './components/AddLocation';
import { EditBase } from './EditBase'

const BOOKING_SYSTEMS_TO_ADD_LOCATIONS = ['square', 'boulevard','mindbody', 'zenoti']

export default (passThroughProps) => {
  const [addLocationMode, setAddLocationMode] = useState(false);
  const [recordToCopy, setRecordToCopy] = useState(undefined);

  const handleClickAddLocationButton = ({record}) => {
    setAddLocationMode(true)
    setRecordToCopy(record)
  }

  const exitAddLocationMode = () => {
    setAddLocationMode(false)
  }

  const AddLocationButton = (props) => {
    if (!BOOKING_SYSTEMS_TO_ADD_LOCATIONS.includes( parseBookingSystem(props.record) )){
      return null
    }
    return <Button label="Add Location" onClick={() => handleClickAddLocationButton(props)}/>
  }

  if (addLocationMode){
    return <AddLocation {...passThroughProps} record={recordToCopy} exitAddLocationMode={exitAddLocationMode}/>
  }

  return <EditBase {...passThroughProps} AddLocationButton={AddLocationButton}/>
}
