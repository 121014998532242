import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab, Button } from 'react-admin';
import { useFormState } from 'react-final-form'

import { makeRequest } from '../../../../helpers'

export const Zenoti = (props) => {
  const [providers, setProviders] = useState([])
  const [zenotiLocations, setZenotiLocations] = useState([])

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getLocations = async (apiKey) => {
    try {
      const locationsResponse = await makeRequest('/zenoti/locations', {apiKey});
      setZenotiLocations(locationsResponse)
    } catch (err){
      alert(err)
      return
    }
  };

  const ShowLocationsButton = () => {
    const { values } = useFormState()
    const apiKey = values.integrationDetails?.zenoti?.apiKey

    if (!apiKey){
      return null
    }

    return <Button label="Get Locations" onClick={() => getLocations(apiKey)} />
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.zenoti.apiKey" label="Zenoti API Key"/>
      <ShowLocationsButton/>
      {
        zenotiLocations.length ? 
        <SelectInput source="integrationDetails.zenoti.centerId" label="Zenoti Location" choices={zenotiLocations}/>
        : null
      }
    </FormTab>
  );
};
