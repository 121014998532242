import React, { useState } from 'react';
import { fetchPut } from '../../helpers'
import { Confirm, Button, useRecordContext, useRedirect } from 'react-admin';
import { CircularProgress } from '@mui/material';
import moment from 'moment-timezone';

  
export const AdminCancelButton = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false);

  const redirect = useRedirect();
  const record = useRecordContext();


  const adminCancel = async () => {
    try {
      setIsLoading(true)
      await fetchPut(`/admin/appointment-cancel/${record._id}`)
      setIsLoading(false)
    } catch(err){
      alert(err)
      setIsLoading(false)
      return
    }
  
    redirect(`/appointments`)
    alert('success')
  }

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    adminCancel();
    setOpen(false);
  };



  if (record.status === 'cancelled' || record.dateTime < moment().toISOString()){
    return null
  }
  if (isLoading){
    return <CircularProgress/>
  }
  return (
    <>
      <Button label="Admin Cancel" style={{marginLeft: 20, color: 'red'}} onClick={handleClick} />
      <Confirm
        confirm="Yes, confirm cancellation"
        cancel="Nevermind"
        isOpen={open}
        title={`Admin Cancel`}
        content="Are you sure you want to cancel this appointment?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
