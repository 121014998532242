import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';

export const NeighborhoodCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()}/>
      </SimpleForm>
    </Create>
  );
};
