import React from "react";
import { TextField as BaseTextField } from "react-admin";

export const TextFieldPreserveNewlines = (props) => {

  return <BaseTextField
    style={{whiteSpace: "pre-wrap"}}
    {...props}
  />
}
TextFieldPreserveNewlines.defaultProps = {
  addLabel: true,
}
