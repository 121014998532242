import React from 'react';
import {
  Create,
  DateInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  required
} from 'react-admin';
import { parse } from 'query-string';
import { OUTREACH_TYPES, LEAD_NOTE_TYPES } from '../leads/enums';
import moment from 'moment-timezone'
import { useFormState, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners'

const today = moment().format('MM-DD-YYYY')

const LeadNoteCreate = props => {
  // Read the leadId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { leadId } = parse(props.location.search);

  const redirect = leadId ? `/leads/${leadId}/show/lead_notes` : false;

  const GenericNoteSection = () => (
    <TextInput multiline source="body" validate={required()}/>
  )

  const ScheduledPhoneCallSection = () => (
    <>
      <DateInput source="phoneCallDate" label="Date of Phone Call" validate={required()}/>
      <TextInput multiline source="phoneCallNote" />
    </>
  )

  const OutreachSection = () => (
    <>
      <DateInput source="outreachDate" label="Date of Outreach" validate={required()}/>
      <SelectInput source="outreachType" choices={OUTREACH_TYPES} validate={required()}/>
      <BooleanInput source="outreachPhoneCallConnection" label="Talked to a stakeholder on the phone?"/>
      <TextInput multiline source="outreachNote" />
    </>
  )

  const NoteTypeSections = () => {
    const { values } = useFormState()

    switch(values.noteType) {
      case 'genericNote':
        return <GenericNoteSection />
      case 'followUp':
        return <GenericNoteSection />
      case 'scheduledPhoneCall':
        return <ScheduledPhoneCallSection />
      case 'salesTemplateOutreach':
        return <OutreachSection />
      default:
        return null
    }
  }

  const NoteTypeInput = () => {
    const form = useForm()

    return (
      <>
        <SelectInput source="noteType" choices={LEAD_NOTE_TYPES} validate={required()} />
        <OnChange name="noteType">
            {(value) => {
              // https://stackoverflow.com/questions/58408001/how-to-add-a-reset-values-button-to-a-react-admin-edit-form
              form.setConfig('keepDirtyOnReinitialize', false)
              form.reset({ leadId, noteType: value })
              form.setConfig('keepDirtyOnReinitialize', true)
            }}
        </OnChange>
      </>
    )
  }

  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ leadId }}
        redirect={redirect}
      >
        <ReferenceInput
          source="leadId"
          reference="leads"
          disabled={true}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <NoteTypeInput />
        <NoteTypeSections />

      </SimpleForm>
    </Create>
  );
};

export default LeadNoteCreate;
