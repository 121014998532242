import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  EditButton,
  SimpleList,
  TextField
} from 'react-admin';


export const NeighborhoodList = (props) => (
  <List {...props} sort={{ field: 'name', order: 'asc' }} bulkActionButtons={false} pagination={null}>
    <Responsive
      small={
        <SimpleList linkType="show" primaryText={record => record.name} />
      }
      medium={
        <Datagrid>
          <TextField sortable={false} source="name" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
)

