import React from 'react';
import {
  Datagrid,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  DateField
} from 'react-admin';

const ReviewTextField = ({ record, source }) => {
  const reviewText = record[source] || ''
  const textToDisplay = reviewText && reviewText.length > 40 ? reviewText.slice(0, 40) + '...' : reviewText
  return (
    <span>{textToDisplay}</span>
  )
};

export const ReviewList = (props) => (
  <List {...props} sort={{ field: 'name', order: 'asc' }} bulkActionButtons={false} pagination={null}>
    <Responsive
      small={
        <SimpleList linkType="show" primaryText={review => `${review.appointment.user.fullName}, ${review.appointment.provider.name}, ${review.appointment.provider.neighborhood}, ${review.appointment.service.name}`} />
      }
      medium={
        <Datagrid>
          <DateField sortable={false} label='Appt Date' source="appointment.dateTime" />
          <TextField sortable={false} label='User' source="appointment.user.fullName" />
          <TextField sortable={false} label='Provider' source="appointment.provider.nameWithNeighborhood" />
          <TextField sortable={false} label='Service' source="appointment.service.name" />
          <TextField sortable={false} source="overall" />
          <ReviewTextField source="text"/>
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
)

