import React, { useEffect, useState } from 'react';

import Chip from '@material-ui/core/Chip';

import { makeRequest } from '../../../helpers';

export default ({record}) => {
  // const [categories, setCategories] = useState([]);
  // const [subcategories, setSubcategories] = useState([]);

  // useEffect(() => {
  //   getCategories();
  //   getSubcategories();
  // }, []);

  // const getCategories = async () => {
  //   const categoriesResponse = await makeRequest('/categories');
  //   setCategories(categoriesResponse);
  // };

  // const getSubcategories = async () => {
  //   const subcategoriesResponse = await makeRequest('/subcategories');
  //   setSubcategories(subcategoriesResponse);
  // };

  return (
    <>
      <b>Categories</b>
      {
        record.categories.map((category) => {
          return (
            <Chip
                key={category._id}
                label={category.name}
            />
          );
        })
      }
      <br></br>
      {record.subcategories.length > 0 &&
      <div>
        <b>Subcategories</b>
        {
          record.subcategories.map((subcategory) => {
            return (
              <Chip
                  key={subcategory._id}
                  label={subcategory.name}
              />
            );
          })
        }
      </div>
      }
    </>
  )
}
