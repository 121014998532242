export const TWO_WAY_OPEN_API = 'twoWayOpenApi'
const ONE_WAY_SCRAPER = 'oneWayScraper'
const TWO_WAY_SCRAPER = 'twoWayScraper'
const NO_INTEGRATION_POSSIBLE = 'noIntegrationPossible'
const DISCOVERY_NEEDED = 'discoveryNeeded'
const PRODUCT_ONLY = 'productOnly'

export const BOOKING_SYSTEM_TYPES = [
  {id: TWO_WAY_OPEN_API, name: '2-Way Open API'},
  {id: ONE_WAY_SCRAPER, name: '1-Way Public API / Scraper'},
  {id: TWO_WAY_SCRAPER, name: '2-Way Public API / Scraper'},
  {id: NO_INTEGRATION_POSSIBLE, name: 'No Integration Possible'},
  {id: DISCOVERY_NEEDED, name: 'Discovery Needed'},
  {id: PRODUCT_ONLY, name: 'Product Only'},
]

const buildLookup = (arrayOfObjects) => {
  let lookup = {}
  arrayOfObjects.forEach(object => lookup[object.id] = object.name) 
  return lookup
}

export const BOOKING_SYSTEM_TYPES_LOOKUP = buildLookup(BOOKING_SYSTEM_TYPES)
