import React, { useEffect, useState }  from 'react';
import { FormTab, TextInput, Button } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { CircularProgress } from '@mui/material';

import { fetchPost, makeRequest } from '../../../../../helpers';

const SaveAmbassadorCode = ({setExistingAmbassadorCode, ...props}) => {
  const providerId = props.record?._id
  const { values } = useFormState();
  const { change } = useForm();

  const saveAmbassadorCode = async () => {
    await fetchPost(`/provider_ambassador_codes`, { code: values.ambassadorCode, provider: providerId})
    alert('success')
    change('ambassadorCode', '')
    setExistingAmbassadorCode(values.ambassadorCode)
  }

  return (
    <Button disabled={!values.ambassadorCode} label="Save" onClick={saveAmbassadorCode}/>
  );
};

const RenderTab = ({isLoading, existingAmbassadorCode, setExistingAmbassadorCode, ...props}) => {
  if (isLoading){
    return <CircularProgress/>
  }

  if (existingAmbassadorCode){
    return <span>{existingAmbassadorCode}</span>
  }

  return (
    <>
      <TextInput label="Ambassador Code" source="ambassadorCode" helperText="Make sure to capitalize" {...props}/>
      <SaveAmbassadorCode setExistingAmbassadorCode={setExistingAmbassadorCode} {...props}/>
    </>
  )
}

export default (props) => {
  const providerId = props.record?._id
  const [existingAmbassadorCode, setExistingAmbassadorCode] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=> {
    if (providerId){
      getAmbassadorCode()
    }
  }, [providerId])

  const getAmbassadorCode = async () => {
    const existingCode = await makeRequest('/provider_ambassador_codes', { provider: providerId })
    if (existingCode){
      setExistingAmbassadorCode(existingCode.code)
    }
    setIsLoading(false)
  }

  return (
    <FormTab label="Ambassador Code" {...props}>
      <RenderTab existingAmbassadorCode={existingAmbassadorCode} setExistingAmbassadorCode={setExistingAmbassadorCode} isLoading={isLoading} {...props}/>
    </FormTab>
  );
};
