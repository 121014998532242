import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SaveButton,
  NumberInput,
  BooleanInput,
  Toolbar,
  required,
  email
} from 'react-admin';
import { requiredRange, validateWebsite, validateInstagram } from './util/validations'


export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={required()} className="w_50" />
        <BooleanInput label="In-home only" source="inHomeOnly" />
        <TextInput source="neighborhood" validate={required()} />
        <TextInput source="address" validate={required()} className="w_50" />
        <TextInput source="priceRange" validate={required()} />
        <NumberInput source="latitude" validate={requiredRange(0, 180)} />
        <NumberInput source="longitude" validate={requiredRange(-180, 0)} />
        <TextInput
          source="description"
          multiline
          validate={required()}
          className="w_50"
        />
        <TextInput source="website" validate={validateWebsite()}/>
        <TextInput source="instagram" validate={validateWebsite()}/>
        <TextInput source="email" validate={email()}/>
        <NumberInput
          source="cancellationWindow"
          validate={requiredRange(0, undefined)}
        />
        <NumberInput
          source="cancellationAmount"
          validate={requiredRange(0, undefined)}
        />
        <NumberInput
          source="cancellationPercentage"
          validate={requiredRange(0, 1)}
        />
        <NumberInput
          source="takeAmount"
          validate={requiredRange(0, undefined)}
        />
        <NumberInput source="takePercentage" validate={requiredRange(0, 1)} />
        <TextInput source="stripeConnectAccountId" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
