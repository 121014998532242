import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { Button } from 'react-admin';

const AddNoteButton = ({ record }) => (
  <Button
    variant="raised"
    component={Link}
    to={`/lead_notes/create?leadId=${record.id}`}
    label="Add a note"
    title="Add a note"
  >
    <ChatBubbleIcon />
  </Button>
);

export default AddNoteButton;
