import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  required
} from 'react-admin';

const BottomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const Title = ({record}) => <span>{record.name}</span>

export const NeighborhoodEdit = props => (
  <Edit {...props} title={<Title/>}>
    <SimpleForm toolbar={<BottomToolbar/>}>
      <TextInput source="name" validate={required()}/>
    </SimpleForm>
  </Edit>
);
