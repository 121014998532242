import React, { useState, useEffect } from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  AutocompleteInput,
  Button,
  required,
  SimpleForm,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { AutocompleteObjectArrayInput } from '../../components/AutocompleteObjectArrayInput'
import { prepend, SubcategoryInput } from '../../components/SubcategoryInput'

import { useFormState, useForm } from 'react-final-form';
import { makeRequest } from '../../helpers'

const BottomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const Title = ({record}) => <span>{record.title}</span>

export default props => {
  const [providers, setProviders] = useState([])
  const [providerServices, setProviderServices] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  useEffect(() => {
    getProviders();
    getCategories()
    getSubcategories()
  }, []);

  const getCategories = async () => {
      const categoriesResponse = await makeRequest('/categories', {isSearchable: true})
      setCategories(categoriesResponse)
  }

  const getSubcategories = async () => {
      const subcategoriesResponse = await makeRequest('/subcategories', {isCurrent: true})
      setSubcategories(subcategoriesResponse)
  }

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getProviderServices = async (selectedProviderId) => {
    const servicesResponse = await makeRequest(`/services?provider=${selectedProviderId}`);
    setProviderServices(servicesResponse);
  };

  const handleProviderSelect = (selectedProviderId) => {
    if (selectedProviderId){
      getProviderServices(selectedProviderId)
    }
  }

  const AddServiceToCuratedListButton = () => {
    const { values } = useFormState();
    const { change, batch } = useForm();

    const addServiceToCuratedList = () => {
      const addedService = providerServices.find( service => service._id === values.selectedService)
      const updatedServiceIds = (values.serviceIds || []).concat(addedService)
      batch(() => {
        change('selectedProvider', undefined)
        change('selectedService', undefined)
        change('serviceIds', updatedServiceIds)
      })
    }

    return (
      <Button disabled={!values.selectedService} label="+ Add Service" onClick={addServiceToCuratedList}/>
    );
  };

  const Services = () => {
    const { values } = useFormState()

    return (
      <AutocompleteObjectArrayInput source="serviceIds" label="Services" choices={values.serviceIds} optionText="providerAndServiceName" optionValue="_id" />
    )
  }

  return (
    <Edit {...props} title={<Title/>}>
      <SimpleForm toolbar={<BottomToolbar/>}>
        <TextInput source="title" validate={required()}/>
        <BooleanInput source="active" label="Active"/>
        <AutocompleteInput disabled={categories.length === 0} source="category" choices={prepend({_id: null, name: ''}, categories)} optionValue="_id" emptyValue={null} emptyText=''/>
        <SubcategoryInput subcategories={subcategories}/>
        <BooleanInput source="inHomeFilter" label="In-Home Only"/>
        <BooleanInput source="hasDiscountFilter" label="Skoop Exclusive"/>
        <NumberInput source="sortOrder" label="Sort Order"/>
        <SelectInput source="selectedProvider" label="Choose a provider" choices={providers} optionText={(record) => `${record.name} (${record.neighborhood})`} optionValue="_id" onChange={e => handleProviderSelect(e.target.value)}/>
        <SelectInput source="selectedService" label="Choose a service" choices={providerServices} optionValue="_id"/>
        <AddServiceToCuratedListButton/>
        <Services/>
      </SimpleForm>
    </Edit>
  );
}
