import React, { useState } from 'react';
import { TextInput, Button } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import ReactJson from 'react-json-view'

import { makeRequest } from '../../../../../helpers'
import { REACT_JSON_DEFAULT_PROPS } from './index'

const ValidateYelpButton = ({setYelpBusiness}) => {
  const { values } = useFormState();
  const { change } = useForm();

  const validateYelpIdentifer = async () => {
    const yelpBusiness = await makeRequest(`/providers/yelp_business/${values.yelpAlias}`)
    if (!yelpBusiness){
      return alert(`No Yelp businesses found with alias ${values.yelpAlias}`)
    }
    change('yelpBusinessId', yelpBusiness.id)
    setYelpBusiness(yelpBusiness)
  }

  return (
    <Button disabled={!values.yelpAlias} label="Validate Yelp alias" onClick={validateYelpIdentifer}/>
  );
};

const SearchSection = ({savedYelpBusinessId, setYelpBusiness, ...rest}) => {
  const [showSearchSection, setShowSearchSection] = useState(!savedYelpBusinessId)

  if (!showSearchSection){
    return <Button label="Update Yelp Business ID" onClick={() => setShowSearchSection(true)} style={{display: 'flex'}}/>
  }
  return (
    <div {...rest}>
      <TextInput source="yelpAlias" helperText="https://www.yelp.com/biz/<ALIAS>"/>
      <ValidateYelpButton setYelpBusiness={setYelpBusiness}/>
    </div>
  )
}

export default (props) => {
  const savedYelpBusinessId = props.record.yelpBusinessId
  const [yelpBusiness, setYelpBusiness] = useState(undefined)

  const getYelpBusinessDetails = async () => {
    const yelpBusinessResponse = await makeRequest(`/providers/${props.record.id}/yelp_business`)
    setYelpBusiness(yelpBusinessResponse)
  }

  const YelpBusinessDetailsButton = () => {
    const { values } = useFormState();
    const showButton = !!savedYelpBusinessId && !yelpBusiness && !values.yelpAlias
    return showButton ? <Button label="Get Yelp Business Details" onClick={getYelpBusinessDetails} style={{display: 'flex'}}/> : null
  }

  return (
    <div {...props}>
      <span style={{'font-weight': 'bold'}}>Yelp</span>
      <SearchSection {...props} savedYelpBusinessId={savedYelpBusinessId} setYelpBusiness={setYelpBusiness}/>
      <TextInput disabled label="Yelp Business ID" source="yelpBusinessId" helperText={yelpBusiness ? yelpBusiness.name : undefined} {...props}/>
      <YelpBusinessDetailsButton/>
      {yelpBusiness && <ReactJson src={yelpBusiness} name={`Yelp Business Details`} {...REACT_JSON_DEFAULT_PROPS}/>}
    </div>
  );
};
