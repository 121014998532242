import React, { useState } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  Toolbar,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  ReferenceField,
  TextField,
  SaveButton,
  required,
  email,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { requiredRange, validateWebsite, validateInstagram } from './util/validations';
import LinkToServices from './components/LinkToServices';
import ImageEditor from './components/ImageEditor/index'
import CategoriesAndSubcategories from './components/CategoriesAndSubcategories';
import GoogleAndYelpTab from './components/Tabs/GoogleAndYelp';
import AmbassadorCodeTab from './components/Tabs/AmbassadorCode';
import LinkToProviderSchedule from './components/LinkToProviderSchedule';

const CustomSaveButton = ({saveDisabled, ...props}) => {
  const formState = useFormState()
  return <SaveButton disabled={saveDisabled || !!formState.values.ambassadorCode} {...props}/>
}

export const EditBase = (props) => {
  const [saveDisabled, setSaveDisabled] = useState(false)

  return (
    <Edit {...props} className="providerEdit">
      <TabbedForm
        toolbar={
          <Toolbar {...props}>
            <CustomSaveButton saveDisabled={saveDisabled}/>
            <props.AddLocationButton />
          </Toolbar>
        }
      >
        <FormTab label="Details">
          <LinkToServices />
          <TextInput disabled source="id" />
          <TextInput source="name" className="w_50" />
          <BooleanInput label="In-home only" source="inHomeOnly" />
          <TextInput source="neighborhood" validate={required()} />
          <TextInput source="address" validate={required()} className="w_50" />
          <TextInput source="priceRange" validate={required()} />
          <NumberInput source="latitude" validate={requiredRange(0, 180)} />
          <NumberInput source="longitude" validate={requiredRange(-180, 0)} />
          <TextInput source="description" multiline className="w_50" />
          <TextInput source="website" validate={validateWebsite()}/>
          <TextInput source="instagram" validate={validateInstagram()}/>
          <TextInput source="email" validate={email()}/>
          <NumberInput source="cancellationWindow" validate={requiredRange(0, undefined)}/>
          <NumberInput source="cancellationAmount" validate={requiredRange(0, undefined)}/>
          <NumberInput source="cancellationPercentage" validate={requiredRange(0, 1)}/>
          <NumberInput source="takeAmount" validate={requiredRange(0, undefined)}/>
          <NumberInput source="takePercentage" validate={requiredRange(0, 1)} />
          <TextInput source="stripeConnectAccountId" validate={required()} />
          <CategoriesAndSubcategories />
          <ImageEditor saveDisabled={saveDisabled} setSaveDisabled={setSaveDisabled}/>
          <BooleanInput label="Archived?" source="archived" />
          <LinkToProviderSchedule />
        </FormTab>
        <GoogleAndYelpTab/>
        <AmbassadorCodeTab/>
      </TabbedForm>
    </Edit>
  );
};
