import List from './List';
import Create from './Create';
import Edit from './EditWithAddLocation';
import GroupIcon from '@material-ui/icons/Group';

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: GroupIcon
};
