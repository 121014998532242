import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('foo');
    }, 300);
  });

export default (
    apiUrl,
    httpClient = fetchUtils.fetchJson,
) => ({
    getList: (resource, params) => {

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };

        if (!resource.includes('lead')){
            query = params.filter
        }

        if (resource == 'categories'){
            query = {isSearchable: true}
        }

        if (resource == 'services'){
            query = {...query, isAdmin: true}
        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        // HACK
        if (resource === 'services' && !params.filter.provider){
            return myPromise.then(() => {
                return {
                    data: [],
                    total: 0
                };
            })
        }

        return httpClient(url).then(({ headers, json }) => {
            const totalCountHeader = headers.get('X-Total-Count')
            return {
                data: json.map(record => ({...record, id: record._id})),
                total: !!totalCountHeader ? parseInt(totalCountHeader) : json.length
            };
        });
    },
    getMany: (resource, params) => {
        // React admin bug: getMany is triggered on ReferenceInput,
        // but it's not actually needed since referenceRecord is never used
        // https://github.com/marmelab/react-admin/blob/dc5f6abf6f0c5a67a288db6718330f2cb2148abb/packages/ra-core/src/controller/input/useReferenceInputController.ts#L132:L142
        
        const query = {
            filter: JSON.stringify({ _id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ 
            data: json.map(record => ({...record, id: record._id})), 
        }));
    },
    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        console.log(url)
        
        return httpClient(url).then(({ json }) => ({
            data: json.map(record => ({...record, id: record._id})),
            total: json.length
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: {...json, id: json._id},
        })),

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: {...json, id: json._id} })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json._id },
        })),
});