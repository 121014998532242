import React, { useState, useEffect } from 'react';
import { SelectInput, TextInput, FormTab, Button } from 'react-admin';
import { useFormState } from 'react-final-form'

import { makeRequest } from '../../../../helpers'

export const Boulevard = (props) => {
  const [providers, setProviders] = useState([])
  const [boulevardBusinessLocations, setBoulevardBusinessLocations] = useState([])

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const providersResponse = await makeRequest('/providers');
    setProviders(providersResponse);
  };

  const getLocations = async (businessId) => {
    try {
      const locationsResponse = await makeRequest('/boulevard/locations', {businessId});
      setBoulevardBusinessLocations(locationsResponse)
    } catch (err){
      alert(err)
      return
    }
  };

  const ShowLocationsButton = () => {
    const { values } = useFormState()
    const businessId = values.integrationDetails?.boulevard?.businessId

    if (!businessId){
      return null
    }

    return <Button label="Get Locations" onClick={() => getLocations(businessId)} />
  }

  return (
    <FormTab {...props} label="Integration Details" path="integration">
      <SelectInput source="provider" label="Choose a provider" choices={providers} optionValue="_id"/>
      <TextInput source="integrationDetails.boulevard.businessId" label="Boulevard Business ID"/>
      <ShowLocationsButton/>
      {
        boulevardBusinessLocations.length ? 
        <SelectInput source="integrationDetails.boulevard.locationId" label="Boulevard Location" choices={boulevardBusinessLocations}/>
        : null
      }
    </FormTab>
  );
};
