import React, { useState } from 'react';
import { fetchPut } from '../../helpers'
import { Button, useRecordContext, useRedirect } from 'react-admin';
import get from 'lodash.get'
import { useFormState } from 'react-final-form';
import { CircularProgress } from '@mui/material';

const requiredFieldsForBookingSystemMapping = {
  'Google Calendar': [
    'integrationDetails.google.writeCalendarId',
    'integrationDetails.google.readCalendarId',
    'provider',
    'primaryContactEmail'
  ],
  'Vagaro': [
    'integrationDetails.google.writeCalendarId',
    'integrationDetails.google.readCalendarId',
    'provider',
    'primaryContactEmail'
  ],
  'Square': [
    'integrationDetails.google.writeCalendarId',
    'integrationDetails.square.bookingLink',
    'integrationDetails.square.squareCredentialSet',
    'provider',
    'primaryContactEmail'
  ],
  'Booker': [
    'integrationDetails.booker.integrationLocationId',
    'provider',
  ],
  'Boulevard': [
    'integrationDetails.boulevard.businessId',
    'integrationDetails.boulevard.locationId',
    'provider',
  ],
  'Mindbody': [
    'integrationDetails.mindbody.siteId',
    'integrationDetails.mindbody.locationId',
    'provider',
  ],
  'Zenoti': [
    'integrationDetails.zenoti.apiKey',
    'integrationDetails.zenoti.centerId',
    'provider',
  ],
  'Acuity (Powerhouse)': [
    'integrationDetails.acuity.apiKey',
    'integrationDetails.acuity.userId',
    'provider',
  ],
  'Acuity (Not Powerhouse)': [
    'integrationDetails.google.writeCalendarId',
    'integrationDetails.public_acuity.integrationProviderId',
    'provider',
  ],
  'Gloss Genius': [
    'integrationDetails.glossgenius.integrationProviderSlug',
    'integrationDetails.glossgenius.integrationStaffId',
    'provider',
  ]
}

const endpointMapping = {
  'Vagaro': 'google',
  'Google Calendar': 'google',
  'Square': 'square',
  'Booker': 'booker',
  'Boulevard': 'boulevard',
  'Mindbody': 'mindbody',
  'Zenoti': 'zenoti',
  'Acuity (Powerhouse)': 'acuity',
  'Acuity (Not Powerhouse)': 'public_acuity',
  'Gloss Genius': 'glossgenius'
}

export const ConvertButton = () => {
  const [isLoading, setIsLoading] = useState(false)

  const redirect = useRedirect();
  const { values } = useFormState();
  const record = useRecordContext();

  const bookingSystem = values?.bookingSystem?.name

  const checkReadyToConvert = () => {
    const requiredFieldsForBookingSystem = requiredFieldsForBookingSystemMapping[bookingSystem]

    for (const field of requiredFieldsForBookingSystem){
      if ( !get(values, field) ){
        return { isReadyToConvert: false, missingField: field}
      }
    }

    return { isReadyToConvert: true }
  }

  const handleClick = async () => {
    const { isReadyToConvert, missingField } = checkReadyToConvert()
    if (!isReadyToConvert){
      alert(`Make sure to fill out all the integration fields. Missing: ${missingField}`)
      return
    }

    const endpoint = endpointMapping[bookingSystem]
    try {
      setIsLoading(true)
      await fetchPut(`/convert_lead/${endpoint}/${record._id}`, values)
      setIsLoading(false)
    } catch(err){
      alert(err)
      setIsLoading(false)
      return
    }

    redirect(`/leads/${record._id}/show`)
    alert('success')
    // TODO - react-admin notify not working, look into https://github.com/fkhadra/react-toastify
    // notify(`Converted!! ${record.name}`, { type: 'success' });
  }

  const VISIBLE_STATUSES = ['needAccess', 'readyToIntegrate']

  if (!VISIBLE_STATUSES.includes(record.status)){
    return null
  }

  return <>
    <Button disabled={isLoading} label="Convert" onClick={handleClick} />
    {isLoading ? <CircularProgress/> : null}
  </>
}
